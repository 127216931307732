@import "../mixins/media";
@import "../mixins/clearfix";

/*


トップページで使用されている「お客様サービス お問い合わせ」についてのスタイルです。


*/

.ec-contactUs {
  box-sizing: border-box;
  border-radius: 10px;
  background: #f8f8f8;
  padding: 20px 20px 60px 20px;
  margin: 30px;

  @include media-breakpoint-down(lg) {
    padding-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    padding: 12px 12px 12px 12px;
    border-radius: 0px;
    margin: 60px 0 0 0;
  }

  &__title {
    h2 {
      text-align: center;
      font-weight: bold;
      margin: 30px 0;
      @include media-breakpoint-down(md) {
        padding: 0;
      }
      @include media-breakpoint-down(sm) {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }

  &__blockWrapper {
    &__block {
      width: calc(100% / 3);
      max-width: 290px;
      @include media-breakpoint-down(lg) {
        width: calc(100% / 2);
        margin-bottom: 28px;
      }
      @include media-breakpoint-down(sm) {
        width: calc(100% / 1);
        margin-bottom: 20px;
      }
      &__largeText {
        text-align: center;
        font-weight: 700;
        &__heading {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 0;
        }
        &__content {
          font-size: 24px;
          margin-bottom: 2px;
          a {
            color: #333333;
            @include media-breakpoint-down(sm) {
              text-decoration: underline;
            }
          }
          @include media-breakpoint-up(md) {
            a[href^="tel:"] {
              pointer-events: none;
              display: block;
            }
          }
        }
      }
      & &__smallText {
        font-size: 12px;
        text-align: center;
      }
      &__button {
        max-width: 216px;
        font-size: 14px;
        background-image: linear-gradient(to right, #4d96cb, #0a7acb);
        color: #ffffff;
        padding: 12px 24px 10px 24px;
        text-align: center;
        margin: 12px auto;
        border-radius: 4px;
        transition: 0.3s;
        display: block;

        &:hover {
          filter: brightness(1.15);
          color: #ffffff;
        }
      }
    }
  }
}
