@import "../mixins/media";
@import "../mixins/animation";
@import "../mixins/projects";
/*
カート

ショッピングカートに関するスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 7.3
*/

/*
カートヘッダ

購入完了までの手順や、現在の状態を表示します。

ul 要素を用いたリスト要素としてマークアップします。

ex [カートページ　ヘッダ部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-progress

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3.1
*/
.ec-progress {
  margin: 0 auto;
  padding: 8px 0 16px;
  display: flex;
  width: 100%;
  list-style: none;

  @include media_desktop {
    margin-bottom: 30px;
    padding: 0;
  }

  & &__item {
    position: relative;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    z-index: 0;
    width: 25%;

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin: auto;
    }

    &:after {
      top: 0px;
      right: 0em;
      border-style: solid;
      border-color: transparent transparent transparent #f8f8f8;
      border-width: 30px 0 30px 1.72em;
      z-index: 15;
    }

    &:last-child:before,
    &:last-child:after {
      display: none;
    }

    div {
      width: 92%;
      background: #f8f8f8;
      text-align: left;
      padding: 10px 20px;
    }
    &:last-child div {
      width: 100%;
    }
  }

  .is-complete {
    div {
      color: #fff;
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    }

    &:after {
      border-color: transparent transparent transparent #167bcb;
    }
  }

  & &__number {
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
    font-size: 12px;
    background: #525263;
    color: #fff;
    top: 0;
    left: 18px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;

    @include media_desktop() {
      line-height: 42px;
      width: 42px;
      height: 42px;
      font-size: 20px;
    }

    & &__label {
      font-size: 12px;
    }
  }
}

/*
カートナビゲーション

カートナビゲーションを表示します。　カートに追加された商品の個数も表示します。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerCart

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.5
*/
.ec-cartNaviWrap {
  @include media_desktop {
    position: relative;
  }
}
.ec-cartNavi {
  display: inline-block;
  padding: 10px 0 0 20px;
  width: auto;
  color: black;
  background: transparent;

  @include media_desktop {
    display: flex;
    justify-content: space-between;
    border-radius: 99999px;
    box-sizing: border-box;
    padding: 12px 17px 10px;
    width: auto;
    min-width: 140px;
    height: 44px;
    white-space: nowrap;
    cursor: pointer;
    background: #f8f8f8;
  }

  & &__icon {
    display: inline-block;
    font-size: 20px;
    @include fadeIn(inline-block, 200ms);
    position: relative;
  }
  & &__badge {
    display: inline-block;
    border-radius: 99999px;
    box-sizing: border-box;
    padding: 5px;
    height: 17px;
    font-size: 10px;
    line-height: 0.7;
    vertical-align: top;
    color: #fff;
    text-align: left;
    white-space: nowrap;
    background-color: #de5d50;
    position: absolute;
    left: 60%;
    top: -10px;

    @include media_desktop {
      display: inline-block;
      min-width: 17px;
      position: relative;
      left: 0;
      top: 0;
    }
  }
  & &__price {
    display: none;

    @include media_desktop {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      vertical-align: middle;
    }
  }
}
.ec-cartNavi__label {
  button {
    display: block;
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    max-width: 140px;
    width: 100%;
    padding: 7px 14px;
    margin: 0 auto 10px;
    border: none;
    line-height: 1.75;
    font-weight: 400;
    font-size: 14px;
    height: auto;
  }
  .add-cart {
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    transition: 0.2s;
    &:hover {
      filter: brightness(1.15);
    }
  }
  .estimate {
    background-image: linear-gradient(to right, #db9919, #db6e1d);
    margin: 0 auto 20px;
    transition: 0.2s;
    &:hover {
      filter: brightness(1.15);
    }
  }
}
.ec-cartNavi.is-active {
  .ec-cartNavi__icon {
    &:before {
      content: "\f00d";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
    }
  }
  .ec-cartNavi__badge {
    display: none;

    @include media_desktop {
      display: none;
    }
  }
}

/*
カートナビゲーションのポップアップ(商品詳細)

カートナビゲーションのポップアップを表示します。カートに追加された商品の詳細が表示されます。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:350px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='close')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    +b.ec-cartNaviIsset
      +e.cart
        +e.cartImage
          img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
        +e.cartContent
          +e.cartContentTitle ミニテーブル
          +e.cartContentPrice ¥ 12,960
            +e.cartContentTax 税込
          +e.cartContentNumber 数量：1
      +e.action
        a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
        a.ec-blockBtn.ec-cartNavi--cancel キャンセル

Styleguide 7.3.6
*/
.ec-cartNaviIsset {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 16px;
  z-index: 20;
  position: absolute;
  right: 0;

  @include media_desktop {
    margin-top: 10px;
    min-width: 256px;
    max-width: 256px;

    &::before {
      display: inline-block;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8.5px 10px 8.5px;
      border-color: transparent transparent #f8f8f8 transparent;
      position: absolute;
      top: -9px;
    }
  }

  & &__cart {
    @include clearfix;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 16px;
    padding-bottom: 32px;
  }

  & &__cartImage {
    float: left;
    width: 45%;

    img {
      width: 100%;
    }
  }

  & &__cartContent {
    float: right;
    width: 55%;
    padding-left: 16px;
    text-align: left;
    box-sizing: border-box;
  }

  & &__action {
    .ec-blockBtn--action {
      color: #fff;
      margin-bottom: 8px;
    }
  }

  & &__cartContentTitle {
    margin-bottom: 8px;
  }

  & &__cartContentPrice {
    font-weight: bold;
  }

  & &__cartContentTax {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    margin-left: 2px;
  }

  & &__cartContentNumber {
    font-size: 14px;
  }
}

.ec-cartNaviIsset.is-active {
  display: block;
}

/*
カートナビゲーションのポップアップ(商品なし)

カートナビゲーションのポップアップを表示します。商品が登録されていない場合の表示です。

ex [カートページ　ナビゲーション部分](http://demo3.ec-cube.net/cart)

Markup:
div(style="height:170px;")
  // 上記のdivはスタイルガイド都合上、高さをもたせるため設置(mocでは不要)
  .is_active
    .ec-cartNavi
      .ec-cartNavi__icon
        img(src='/moc/icon/cart-dark.svg', alt='cart')
      .ec-cartNavi__iconClose
        img(src='/moc/icon/cross-dark.svg', alt='close')
      .ec-cartNavi__badge 1
      .ec-cartNavi__label
        | 合計
        .ec-cartNavi__price ¥1920
    .ec-cartNaviNull
      .ec-cartNaviNull__message
        p 現在カート内に
          br
          | 商品がございません。
    //+b.ec-cartNaviIsset
    //  +e.cart
    //    +e.cartImage
    //      img(src='http://demo3.ec-cube.net/upload/save_image/0701104933_5593472d8d179.jpeg')
    //    +e.cartContent
    //      +e.cartContentTitle ミニテーブル
    //      +e.cartContentPrice ¥ 12,960
    //        +e.cartContentTax 税込
    //      +e.cartContentNumber 数量：1
    //  +e.action
    //    a.ec-blockBtn--action(href="/moc/guest/cart1") カートへ進む
    //    a.ec-blockBtn キャンセル

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>


Styleguide 7.3.7
*/

.ec-cartNaviNull {
  display: none;
  width: 100%;
  text-align: center;
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 16px;
  z-index: 3;
  position: absolute;
  right: 0;

  @include media_desktop {
    margin-top: 10px;
    min-width: 256px;
    max-width: 256px;

    &::before {
      display: inline-block;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8.5px 10px 8.5px;
      border-color: transparent transparent #f8f8f8 transparent;
      position: absolute;
      top: -9px;
    }
  }

  & &__message {
    border: 1px solid #d9d9d9;
    padding: 16px 0;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #f99;
    p {
      margin: 0;
    }
  }
}

.ec-cartNaviNull.is-active {
  display: block;
}

/*
総計

会計時の合計金額、総計を表示します。

ex [カートページ　統計部分](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/7.3.cart.pug
+ec-totalBox

Styleguide 7.3.8
*/
.ec-totalBox {
  background: #f3f3f3;
  padding: 16px;
  margin-bottom: 16px;
  & &__spec {
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 8px;
    dt {
      font-weight: normal;
      text-align: left;
    }
    dd {
      text-align: right;
    }

    & &__specTotal {
      color: $clrRed;
    }
  }
  & &__total {
    border-top: 1px dotted #ccc;
    padding: 8px 0;
    text-align: right;
    font-size: 14px;
    font-weight: bold;
  }
  & &__paymentTotal {
    border-top: 1px dotted #ccc;
    padding: 8px 0;
    text-align: right;
    font-size: 14px;
    font-weight: bold;
    .ec-totalBox__price,
    .ec-totalBox__taxLabel {
      color: $clrRed;
    }
  }
  & &__price {
    margin-left: 16px;
    font-size: 16px;
    font-weight: bold;
    @include media_desktop {
      font-size: 24px;
    }
  }

  & &__taxLabel {
    margin-left: 8px;
    font-size: 12px;

    @include media_desktop {
      font-size: 14px;
    }
  }

  & &__taxRate {
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: 8px;
    font-size: 10px;

    @include media_desktop {
      font-size: 12px;
    }
    dt {
      font-weight: normal;
      text-align: left;
      margin-right: 8px;

      &::before {
        content: "[ ";
      }
    }
    dd {
      text-align: right;

      &::after {
        content: " ]";
      }
    }
  }
  & &__pointBlock {
    padding: 18px 20px 10px;
    margin-bottom: 10px;
    background: #fff;
  }

  & &__btn {
    @include reset_link();
    color: #fff;

    .ec-blockBtn--action {
      font-size: 16px;
      font-weight: bold;
      @include media_desktop {
        font-weight: 500;
      }
    }

    .ec-blockBtn--cancel {
      margin-top: 8px;
      color: #000;
    }
  }
}
