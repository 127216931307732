@import "../mixins/media";
@import "../mixins/projects";

/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/

/*
───────────────────────────────────────
カートSTEP2〜4のレイアウト関係
*/
#page_shopping,
#page_shopping_confirm,
#page_shopping_complete {
  .ec-layoutRole .ec-layoutRole__flexLeft {
    width: 100%;
  }
}

/*
───────────────────────────────────────
プログレスバー関係
*/
.ec-cartRole__progressBar {
  padding-top: 6.5vw;

  @include media-breakpoint-down(md) {
    padding-top: 10.5vw;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 13vw;
  }

  .progress {
    margin: 0 5vw;
    height: 2.089vw;
    .progress-bar {
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    }
  }

  .progress-item-wrapper {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    .progress-item {
      text-align: center;
      margin-top: -6.5vw;
      width: 10vw;
      .badge {
        font-size: 5vw;
        line-height: 10vw;
        border-radius: 10vw;
        width: 10vw;
        height: 10vw;
        padding: 0;
        background-image: linear-gradient(to right, #cccccc, #cccccc);
      }
      .heading-text {
        padding: 6px 0;
        font-size: 18px;
        font-weight: 700;
        white-space: nowrap;
        @include media-breakpoint-down(sm) {
          font-size: 3.6vw;
        }
      }
    }
    .progress-item.complete,
    .progress-item.active {
      .badge {
        background-image: linear-gradient(to right, #4d96cb, #0a7acb);
      }
    }
  }
}

/*
───────────────────────────────────────
スタイリング
*/
.ec-cartStep {
  margin: 0px;
}

/*ステップ1-3共通*/
.ec-cartRole .ec-pageHeader,
.ec-shopping .ec-pageHeader {
  margin: 60px 0 0;

  @include media-breakpoint-down(md) {
    margin-top: 24px;
    margin-bottom: 20px;
  }
}

.ec-cartRole {
  @include container;

  @include media-breakpoint-up(md) {
    padding-left: 0;
    padding-right: 0;
  }

  &::before {
    display: none;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  & &__progress {
    width: 100%;
    text-align: center;
  }
  .ec-progress {
    margin-bottom: 0;
  }
  .ec-progress__item {
    width: calc(100% / 4);
    div {
      margin: 0 0 8px 0;
      height: 60px;
      width: calc(100% - 8px);
      background-color: #f3f3f3;
      @include media-breakpoint-down(lg) {
        padding: 10px 12px;
      }
    }
    &:after {
      display: none;
      border-width: 30px 0 30px 2.1em;
      right: -0.9em;
    }
  }

  & &__error {
    width: 100%;
    text-align: center;

    .ec-alert-warning {
      max-width: 80%;
      display: inline-block;
      margin-top: 30px;
    }
  }

  & &__totalText {
    margin-bottom: 0;
    padding: 16px 0 6px;
    width: 100%;
    text-align: center;
    font-weight: normal;

    @include media_desktop {
      margin-bottom: 30px;
      padding: 0;
    }
  }

  form.ec-cartRole {
    padding-left: 0;
    padding-right: 0;
  }

  & &__cart {
    margin: 0;
    width: 100%;

    @include media_desktop {
      margin: 0;
    }
  }

  & &__actions {
    text-align: right;
    width: 100%;
  }

  & &__total {
    padding: 15px 0 30px;
    font-weight: bold;
    font-size: 16px;
  }

  & &__totalAmount {
    margin-left: 8px;
    color: #000;
    font-size: 18px;
    font-weight: bold;

    @include media_desktop {
      font-size: 24px;
    }
  }

  .ec-blockBtn--action {
    margin-bottom: 10px;
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    border: none;
    font-weight: 500;
    border-radius: 3px;
    width: 192px;
    &:hover {
      background-image: linear-gradient(
        to right,
        rgba(77, 150, 203, 0.8),
        rgba(10, 122, 203, 0.8)
      );
    }
  }

  .ec-blockBtn--cancel {
    background: #fff;
    font-weight: 500;
    border-radius: 5px;
    width: 155px;
    border: 1px solid #ccc;
    color: #000;

    background: #e6e6e6;
    border: none;
    width: 192px;
  }
  @include media-breakpoint-down(sm) {
    .ec-blockBtn--action,
    .ec-blockBtn--cancel {
      margin: 12px 0;
    }
    .ec-blockBtn--action {
      order: 1;
    }
    .ec-blockBtn--cancel {
      order: 2;
    }
  }

  &__actionbutton {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;
    }
  }
}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/

.ec-cartTable {
  display: table;
  width: 100%;
  @include media-breakpoint-down(md) {
    padding-top: 8px;
  }
}
.ec-cartTable {
  @include media-breakpoint-down(md) {
    border-top: 1px dotted #ccc;
  }
}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader {
  display: none;
  width: 100%;
  background: #f4f3f0;

  @include media_desktop {
    display: table-row;
  }

  @include media_desktop {
    display: table-row;
  }

  & &__label {
    display: table-cell;
    padding: 16px;
    text-align: center;
    background: #f3f3f3;
    overflow-x: hidden;
    font-size: 14px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .label-left {
    text-align: left;
  }

  .label-center {
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
}

.ec-cartCompleteRole {
  @include container;
  padding: 0 20px;

  margin: 0 auto 60px;

  @include media_desktop {
    margin: 50px auto 120px;
  }
  .ec-reportDescription {
    text-align: left;
    margin: 40px 0;
  }
  .ec-ordernumber {
    text-align: center;
    margin: 30px 0;
    border: 1px solid #ccc;
    padding: 30px 0;
    span {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .ec-blockBtn--cancel {
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    border: none;
    width: 160px;
    margin: 0 auto;
    color: #fff;
  }
}

/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/

.ec-cartRow {
  display: table-row;

  @include media-breakpoint-down(md) {
    border-bottom: 1px solid #cccccc;
    display: block;
    padding-bottom: 24px;
    padding-left: 0;
    margin-bottom: 8px;
  }

  & &__delColumn {
    border-bottom: 1px dotted #ccc;
    text-align: center;
    display: table-cell;
    width: 14%;
    vertical-align: middle;
    @include media_desktop {
      width: 8.3333333%;
    }

    .ec-icon {
      img {
        width: 1.5em;
        height: 1.5em;

        @include media_desktop {
          width: 1em;
          height: 1em;
        }
      }
    }
  }

  &__contentColumn {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    display: table;

    @include media_desktop {
      display: table-cell;
    }

    @include media-breakpoint-down(md) {
      display: block;
      border-bottom: none;
    }
  }

  &__img {
    display: table-cell;
    width: 40%;
    vertical-align: middle;
    padding-right: 10px;

    @include media_desktop {
      display: inline-block;
      min-width: 80px;
      max-width: 128px;
      padding-right: 0;
    }

    @include media-breakpoint-down(md) {
      width: 96px;
      padding-right: 16px;
      vertical-align: top;
    }
  }

  &__summary {
    display: table-cell;
    margin-left: 5px;
    vertical-align: middle;
    width: 46%;
    font-size: 12px;

    @include media_desktop {
      display: inline-block;
      margin-left: 20px;
      vertical-align: middle;
    }

    @include media-breakpoint-down(md) {
      vertical-align: top;
      width: calc(100% - 96px);
    }

    div {
      margin: 3px 0;
    }

    .ec-cartRow__manufacturerName {
      font-size: 0.7rem;
      margin: 5px 0;
    }

    .ec-cartRow__name {
      margin-bottom: 5px;
      font-weight: bold;
      font-size: 14px;

      a {
        color: #000;
      }
    }

    .ec-cartRow__sutbtotalSP {
      display: block;
      font-weight: normal;

      @include media_desktop {
        display: none;
      }
    }

    .ec-cartRow__delete {
      a {
        color: #6086cc;
      }
    }
  }

  &__amountColumn {
    display: table-cell;
    border-bottom: 1px dotted #ccc;
    vertical-align: middle;
    text-align: center;
    width: 20%;
    border-bottom: 1px solid #ccc;
    padding: 0 16px;

    @include media_desktop {
      width: 14%;
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }

    @include media-breakpoint-down(md) {
      display: block;
      border-bottom: none;
      width: auto;
      padding: 0px;
    }

    .ec-cartRow__amount {
      display: block;
      padding: 10px 8px;
      width: fit-content;
      border: 1px solid #ccc;
      min-width: 4em;
      margin: 0 6px;
      @include media-breakpoint-down(md) {
        padding: 6px 8px;
        font-size: 13px;
      }
    }

    .ec-cartRow__amountSP {
      display: none;
      margin-bottom: 10px;

      @include media_desktop {
        display: none;
      }
    }

    .ec-cartRow__amountUpDown {
      display: flex;
      justify-content: center;
      align-items: center;

      @include media_desktop {
      }
      @include media-breakpoint-down(md) {
        justify-content: flex-start;
        margin-left: 96px;
      }
    }

    .ec-cartRow__amountUpButton {
      margin: 0 2px;
      display: inline-block;
      border-radius: 50%;
      width: 24px;
      min-width: 24px;
      max-width: 24px;
      height: 24px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #dedede;
      transition: 0.2s;
      &:hover {
        filter: brightness(1.05);
      }

      .ec-cartRow__amountUpButton__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          display: block;
          width: 0.7em;
          height: 0.7em;
        }
      }
    }

    .ec-cartRow__amountDownButton {
      margin: 0 2px;
      display: inline-block;
      border-radius: 50%;
      width: 24px;
      min-width: 24px;
      max-width: 24px;
      height: 24px;
      cursor: pointer;
      line-height: 40px;
      vertical-align: middle;
      position: relative;
      text-align: center;
      background: #dedede;
      transition: 0.2s;
      &:hover {
        filter: brightness(1.05);
      }

      .ec-cartRow__amountDownButton__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          display: block;
          width: 0.7em;
          height: 0.7em;
        }
      }
    }

    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
      background: #dedede;
      &:hover {
        filter: brightness(1);
      }
    }
  }

  &__stock {
    font-size: 14px;
    margin-top: 10px;
    @include media-breakpoint-down(md) {
      margin-left: 96px;
    }
  }

  & &__subtotalColumn {
    display: none;
    border-bottom: 1px dotted #ccc;
    text-align: center;
    width: 10%;
    border-bottom: 1px solid #ccc;
    padding: 0 16px;

    @include media_desktop {
      display: table-cell;
    }

    @include media-breakpoint-down(md) {
      display: block;
      border-bottom: none;
      text-align: right;
      width: auto;
      font-weight: 700;
      padding-top: 8px;
      padding: 8px 0 0;
    }
  }
}

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/

.ec-cartRow {
  & &__amountColumn {
    .ec-cartRow__amountDownButtonDisabled {
      @extend .ec-cartRow__amountDownButton;
      cursor: default;
    }
  }
}

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/

.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #f99;
  margin-bottom: 20px;

  & &__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top;
  }

  & &__text {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    position: relative;
  }
}

.cart-warning-spacing {
  margin: 130px 0;
}

/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.7
*/
