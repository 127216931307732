@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/clearfix";
@import "../mixins/animation";

.ec-topCategory {
  margin: 60px auto;
  padding: 0 30px;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-weight: bold;
      margin: 0;
    }
  }
  &__list {
    width: calc(100% + 20px);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    margin: 30px 0;

    &__listItem {
      width: calc(20% - (100px / 5));
      margin: 0 20px 20px 0;
      background: #f8f8f8;
      border-radius: 12px;

      transition: 0.2s;
      &:hover {
        box-shadow: 0px 5px 10px #c0c0c080;
        transform: scale(1.02);
      }

      a {
        height: 100%;
        display: block;
      }

      &__box {
        width: 100%;
        padding: 10px;
        text-align: center;

        &__image-block {
          width: 100%;
          padding: 0 0 10px 0;

          img {
            width: 100%;
            height: calc(((((100vw - 400px) / 5) / 3) * 2));
            object-fit: cover;
          }
        }

        &__title {
          width: 100%;
          padding: 10px;
          font-size: 16px;
          font-weight: 400;
          padding-top: 10px;
          color: #181818;
        }
      }
    }
    .category-contact p {
      padding: 10px 0px;
    }
    @include media-breakpoint-down(sm) {
      .category-contact p {
        padding: 0;
      }
    }
  }
}

.ec-definitions--soft {
  @extend .ec-definitions;
  & dt {
    font-weight: normal;
  }
}

@include media-breakpoint-down(lg) {
  .ec-topCategory {
    &__title {
      display: block;
      .ec-free_word {
        padding: 8px 16px;
        background-color: #f3f3f3;
        margin-top: 20px;
      }
    }
    &__list {
      width: calc(100% + 20px);
      margin: 20px 0;

      &__listItem {
        width: calc(25% - (80px / 4));
        margin: 0 20px 20px 0;

        &__box {
          padding: 8px;
          border-radius: 8px;

          &__image-block {
            img {
              height: calc(((((100vw - 340px) / 4) / 3) * 2));
            }
          }

          &__title {
            font-size: 14px;
            padding-top: 8px;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .ec-topCategory {
    margin: 40px auto;

    padding: 0;
    &__title {
      .ec-free_word {
        display: none;
      }
      h2 {
        width: calc(100% - 24px);
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__list {
      width: calc(100% - 12px);
      margin-left: 12px;

      &__listItem {
        width: calc((100% / 3) - (36px / 3));
        margin: 0 12px 12px 0;

        &__box {
          &__image-block {
            img {
              height: calc(((((100vw - 140px) / 3) / 3) * 2));
            }
          }

          &__title {
            font-size: 13px;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .ec-topCategory {
    &__title {
      h2 {
        width: calc(100% - 16px);
        margin-left: auto;
        margin-right: auto;
      }
    }
    &__list {
      width: calc(100% - 8px);
      margin-left: 8px;

      &__listItem {
        width: calc((100% / 3) - (24px / 3));
        margin: 0 8px 8px 0;

        &__box {
          padding: 8px;

          &__image-block {
            padding: 0 0 8px 0;
            img {
              height: calc(((((100vw - 60px) / 3) / 3) * 2));
            }
          }

          &__title {
            font-size: 11px;
            padding: 3px 0 0 0;
          }
        }
      }
    }
  }
}
