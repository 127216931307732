@import "../mixins/media";
/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

ex [お問い合わせ](http://demo3.ec-cube.net/contact)

Markup:
include /assets/tmpl/elements/19.2.contact.pug
+ec-contactRole

Styleguide 19.2

*/

/*
───────────────────────────────────────
レイアウト関係
*/
#page_contact .ec-layoutRole .ec-layoutRole__flexLeft,
#page_contact_complete .ec-layoutRole .ec-layoutRole__flexLeft {
  width: 100%;
}

/*
───────────────────────────────────────
プログレスバー関係
*/
.ec-contactRole__progressBar {
  padding-top: 6.5vw;

  .progress {
    margin: 0 5vw;
    height: 2.089vw;
    .progress-bar {
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    }
  }

  .progress-item-wrapper {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    .progress-item {
      text-align: center;
      margin-top: -6.5vw;
      width: 10vw;
      .badge {
        font-size: 5vw;
        line-height: 10vw;
        border-radius: 10vw;
        width: 10vw;
        height: 10vw;
        padding: 0;
        background-image: linear-gradient(to right, #cccccc, #cccccc);
      }
      .heading-text {
        padding: 6px 0;
        font-size: 18px;
        font-weight: 700;
        @include media-breakpoint-down(sm) {
          font-size: 3.6vw;
        }
      }
    }
    .progress-item.complete,
    .progress-item.active {
      .badge {
        background-image: linear-gradient(to right, #4d96cb, #0a7acb);
      }
    }
  }
}

/*
───────────────────────────────────────
スタイリング
*/
.ec-contactRole {
  @include container;
  margin-bottom: 50px;
  & &__actions {
    padding-top: 20px;
  }
  p {
    margin: 16px 0;
    font-size: 14px;
  }
  .ec-blockBtn--action {
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    font-weight: 500;
    transition: 0.2s;
    &:hover {
      filter: brightness(1.1);
    }

    @include media-breakpoint-down(md) {
      margin-top: 0px;
      margin-bottom: 56px;
    }
  }
  .ec-pageHeader {
    margin: 60px 0 0;

    @include media-breakpoint-down(md) {
      margin-top: 24px;
      margin-bottom: 40px;
    }
  }
  .ec-off1Grid .ec-off1Grid__cell {
    width: 100%;
    padding: 0 8px;
    margin: 0;
    @include media-breakpoint-down(sm) {
      padding: 0 4px;
    }
  }
  .ec-borderedDefs {
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      border: none;
    }
    dl {
      padding: 0;
      @include media-breakpoint-down(md) {
        border: none;
        padding-bottom: 24px;
      }
    }
    dt {
      display: flex;
      align-items: center;
      background: #f3f3f3;
      padding: 0 16px;

      @include media-breakpoint-down(md) {
        padding: 8px;
      }

      .ec-label {
        margin: 0;
        font-weight: 500;

        @include media-breakpoint-down(md) {
          font-weight: 700;
          font-size: 14px;
        }
      }
      .ec-required {
        background-image: linear-gradient(to right, #4d96cb, #0a7acb);
        color: #fff;
        padding: 3px 5px;
        width: 35px;
        margin: 0 0 0 auto;
      }
    }
    dd {
      margin: 0;
      padding: 16px 20px;

      @include media-breakpoint-down(md) {
        padding: 8px 0;
      }

      .ec-halfInput {
        display: flex;
        justify-content: space-between;
        input {
          width: 49%;
        }

        .ec-halfWidth {
          width: 49%;
        }

        .ec-halfWidth .ec-fullWidth {
          width: 100%;
        }

        .ec-halfWidth .ec-errorMessage {
          width: 100%;
        }
      }

      input {
        margin: 10px 0;
      }
      .ec-zipInput {
        span {
          padding: 0 5px 0 0;
          margin-left: 0;
        }
        .ec-zipInputHelp {
          span {
            vertical-align: unset;
            font-size: 13px;
          }
        }
      }
    }
  }
  .ec-cartRole__progress {
    margin: 0 auto;
  }
  .ec-progress__item {
    width: calc(100% / 3);
    div {
      margin: 0 0 8px 0;
      height: 60px;
      width: calc(100% - 8px);
      background-color: #f3f3f3;
    }
    &:after {
      display: none;
      border-width: 30px 0 30px 2.1em;
      right: -0.9em;
    }
  }
}

.ec-contactConfirmRole {
  @include media-breakpoint-down(md) {
    margin-bottom: 106px;
  }

  .ec-pageHeader {
    @include media-breakpoint-down(md) {
      margin-top: 24px !important;
      margin-bottom: 40px;
    }
  }

  @include container;
  & &__actions {
    padding-top: 20px;
  }
  p {
    margin: 16px 0;
    font-size: 14px;
  }
  .ec-off4Grid .ec-off4Grid__cell {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;
  }
  .ec-blockBtn--action {
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    border: none;
    border-radius: 5px;
    margin: 0 0 0 24px;
    width: 50%;
    font-weight: 500;
    min-width: 156px;
    transition: 0.2s;
    &:hover {
      filter: brightness(1.1);
    }
  }
  .ec-blockBtn--cancel {
    background: #fff;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: #000;
    width: 50%;
    font-weight: 500;
    transition: 0.2s;
    &:hover {
      background: #f3f3f3;
    }
  }
  .ec-pageHeader {
    margin: 60px 0 0;

    @include media-breakpoint-down(sm) {
      margin-top: 16px;
    }
  }
  .ec-off1Grid .ec-off1Grid__cell {
    width: 100%;
    padding: 0 8px;
    margin: 0;
    @include media-breakpoint-down(sm) {
      padding: 0 4px;
    }
  }
  .ec-borderedDefs {
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      border: none;
    }
    dl {
      padding: 0;
      @include media-breakpoint-down(md) {
        border: none;
        padding-bottom: 24px;
      }
    }
    dt {
      display: flex;
      align-items: center;
      background: #f3f3f3;
      padding: 0 15px;
      @include media-breakpoint-down(md) {
        padding: 8px;
      }
      .ec-label {
        margin: 0;
        font-weight: 500;

        @include media-breakpoint-down(md) {
          font-weight: 700;
          font-size: 14px;
        }
      }
      .ec-required {
        background-image: linear-gradient(to right, #4d96cb, #0a7acb);
        color: #fff;
        padding: 3px 5px;
        width: 35px;
        margin: 0 0 0 auto;
      }
    }
    dd {
      margin: 0;
      padding: 16px 20px;

      @include media-breakpoint-down(md) {
        padding: 8px 0;
      }

      input {
        margin: 0;
      }
    }
  }
  .ec-cartRole__progress {
    margin: 0 auto;
  }
  /**/
  .ec-progress__item {
    width: calc(100% / 3);
    div {
      margin: 0 0 8px 0;
      height: 60px;
      width: calc(100% - 8px);
      background-color: #f3f3f3;
    }
    &:after {
      display: none;
      border-width: 30px 0 30px 2.1em;
      right: -0.9em;
    }
  }
}
.ec-contactCompleteRole {
  margin: 0 auto 50px auto !important;
  min-height: 50vh;

  @include container;
  .ec-cartRole__progress {
    margin: 0 auto;
  }
  .ec-progress__item {
    width: calc(100% / 3);
    div {
      margin: 0 0 8px 0;
      height: 60px;
      width: calc(100% - 8px);
      background-color: #f3f3f3;
    }
    &:after {
      display: none;
      border-width: 30px 0 30px 2.1em;
      right: -0.9em;
    }
  }
  .ec-reportHeading {
    text-align: left;
    margin: 60px 0 0;
    border: none;

    @include media-breakpoint-down(md) {
      margin-top: 24px;
      margin-bottom: 40px;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 24px;
      padding: 0 8px;

      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }
  }
  .ec-reportDescription {
    margin: 16px 0;
    padding: 0 8px;
    font-size: 14px;
    text-align: left;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
  .ec-off3Grid .ec-off3Grid__cell {
    margin-left: 0;
  }
  .ec-off4Grid .ec-off4Grid__cell {
    width: auto;
    margin: 0 auto;
  }
  .ec-blockBtn--cancel {
    font-weight: 500;
    width: 160px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px !important;
  }
}
