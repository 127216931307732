.ec-itemCards {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  a {
    color: #000;
  }

  &__content {
    width: 19%;
    margin-right: 13px;
    display: flex;
    flex-direction: column;

    &:hover {
      border-radius: 15px 15px;
      box-shadow: 0px 5px 10px #c0c0c0;
      transition: 0.2s;
    }
  }

  &__img {
    position: relative;

    img {
      width: 100%;
      border-radius: 15px 15px 0 0;
      object-fit: cover;
      aspect-ratio: 1 / 1;
    }
  }

  &__detail {
    background: #f8f8f8;
    padding: 15px 15px 30px 15px;
    font-size: 12px;
    border-radius: 0 0 15px 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__name {
    font-size: 14px;
    margin: 5px 0 10px;
  }

  &__labelwrap {
    display: flex;
  }

  &__label {
    display: inline-block;
    color: #fff;
    padding: 2.5px 5px;
    font-size: 10px;
    margin: 3px 6px 3px 0;
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
  }

  &__sales {
    padding-top: 5px;
  }

  &__price {
    font-size: 20px;
    font-weight: bold;
  }
}

.ec-itemLists {
  &__content {
    display: flex;
    border-bottom: 2px solid #f8f8f8;
    padding: 20px 0;
    form {
      display: flex;
      width: 100%;

      @include media-breakpoint-down(sm) {
        padding: 8px 0;
      }

      .imageBlock {
        width: 25%;
        @include media-breakpoint-down(sm) {
          width: 33%;
        }
        a {
          transition: 0.2s;
          &:hover {
            opacity: 0.75;
          }
        }
        img {
          width: 100%;
          padding: 10px;
        }
      }
      .bodyBlock {
        width: 75%;
        display: flex;
        @include media-breakpoint-down(sm) {
          width: 67%;
          display: block;
        }

        .infoBlock {
          width: calc(100% - 184px);
          border-right: 2px solid #f8f8f8;
          padding: 10px;

          @include media-breakpoint-down(sm) {
            width: 100%;
            border-right: none;
            padding-bottom: 0px;
          }

          p {
            font-size: 12px;
            margin: 5px 0;
          }
          .infoBlock-maker {
            a {
              color: #333333;
              text-decoration: underline;
              transition: 0.2s;
              &:hover {
                opacity: 0.5;
              }
            }
          }
          .infoBlock-name {
            font-size: 16px;
            a {
              color: #333333;
              text-decoration: underline;
              transition: 0.2s;
              &:hover {
                opacity: 0.5;
              }
            }
          }
          &__label {
            display: inline-block;
            color: #fff;
            padding: 5px;
            margin: 3px 6px 3px 0;
            background-image: linear-gradient(to right, #4d96cb, #0a7acb);
          }
          &__price {
            font-size: 20px;
            font-weight: bold;
          }
        }
        .actionBlock {
          width: 184px;
          padding: 10px;
          margin: 15px 0;
          text-align: center;

          @include media-breakpoint-down(sm) {
            display: none;
            width: 100%;
            margin: 0;
            padding-top: 0;
            padding-left: 0;

            text-align: left;
          }
          .ec-numberInput {
            @include media-breakpoint-down(sm) {
              padding-top: 0;
            }
          }

          &__cart {
            @include media-breakpoint-down(sm) {
              padding-left: 8px;
            }
            .ec-cartNavi__button {
              @include media-breakpoint-down(sm) {
                margin-left: 0;
                margin-right: 0;
              }
            }
          }
        }
      }
      .ec-productRole__actions {
        margin-bottom: 16px;
        @include media-breakpoint-down(sm) {
          margin-bottom: 4px;
        }
      }
    }
  }
}

.ec-itemCardsSlick {
  width: calc(100% + 64px);
  margin-left: -32px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    margin-left: 0;
  }

  a {
    color: #000;
  }

  &__content {
    padding: 0 10px 16px 10px;
    height: 100%;
    transition: 0.2s;
    &:hover {
      transform: scale(1.025);
    }
  }

  &__inner {
    height: 100%;
    background: #f8f8f8;
    border-radius: 15px;
    transition: 0.2s;
    &:hover {
      box-shadow: 0px 5px 10px #c0c0c080;
    }
  }

  &__img {
    position: relative;

    img {
      width: 100%;
      border-radius: 15px 15px 0 0;
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  }

  &__detail {
    padding: 15px 15px 30px 15px;
    font-size: 12px;
    position: relative;
  }

  &__name {
    font-size: 14px;
    margin: 5px 0 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  &__labelwrap {
    display: flex;
    flex-wrap: wrap;
  }

  &__label {
    display: inline-block;
    color: #fff;
    padding: 6px 5px 5px 5px;
    margin: 3px 6px 3px 0;
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    white-space: nowrap;
  }

  &__sales {
    padding-top: 5px;
  }

  &__price {
    font-size: 20px;
    font-weight: bold;
  }

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-list {
    padding: 10px 48px;

    @include media-breakpoint-down(sm) {
      padding: 10px 48px 10px 16px;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    color: #fff;
    opacity: 0.75; /*デフォルトは.75が指定されている*/
    background-color: #000;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0;
  }
  .slick-next {
    right: 0px;
    z-index: 1;
  }
  .slick-prev {
    left: 0px;
    z-index: 1;
  }
  .slick-dots li button:before {
    content: "●" !important;
  }

  .slick-track {
    display: flex;
    margin: 0;
  }
  .slick-slide {
    height: auto !important;
  }
  .slick-slide > div,
  .slick-slide > div > a {
    height: 100%;
  }
}
