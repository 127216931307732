@import "../mixins/media";
@import "../mixins/clearfix";
@import "../mixins/projects";

/*
注文内容確認

カート内 注文内容確認に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/shopping)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderRole

Styleguide 15.2
*/
.ec-orderRole {
  @include container;
  display: flex;
  flex-direction: column;
  margin-top: 0;

  h2 {
    background: #f8f8f8;
    font-weight: 500;
  }

  @include media_desktop {
    margin-top: 20px;
    flex-direction: row;
  }

  .ec-inlineBtn {
    font-weight: normal;

    .mypage & {
      font-weight: bold;
    }
  }

  & &__detail {
    padding: 0;
    width: 100%;

    @include media_desktop {
      // padding: 0 16px;
      // width: 66.66666%;
    }
  }

  & &__summary {
    width: 100%;

    .ec-inlineBtn {
      display: inline-block;
    }

    @include media_desktop {
      width: 33.33333%;
      padding: 0 16px;

      .ec-inlineBtn {
        display: none;
      }
    }
    .ec-blockBtn--action {
      color: #fff;
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
      border: none;
      border-radius: 3px;
      &:hover {
        background-image: linear-gradient(
          to right,
          rgba(77, 150, 203, 0.8),
          rgba(10, 122, 203, 0.8)
        );
      }
      &:focus {
        box-shadow: none;
      }
    }
  }

  .ec-borderedList {
    margin-bottom: 20px;
    border-top: 1px dotted #ccc;

    @include media_desktop {
      border-top: none;
    }
  }

  &__actions {
    justify-content: space-between;

    .ec-blockBtn--action {
      margin-bottom: 8px;
      margin-bottom: 10px;
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
      border: none;
      font-weight: 500;
      border-radius: 5px;
      width: 192px;
    }

    .ec-blockBtn--cancel {
      background: #fff;
      font-weight: 500;
      border-radius: 5px;
      width: 155px;
      border: 1px solid #ccc;
      color: #000;
    }
  }
}

/*
注文履歴詳細 オーダ情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderInfo

Styleguide 15.2.1
*/
.ec-orderOrder {
  margin-bottom: 30px;

  & &__items {
    @include borderBottom;
    @include borderTop;
  }
}

/*
注文履歴詳細 お客様情報

マイページ 注文詳細部に関する Project コンポーネントを定義します。

ex [マイページ　オーダ情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAccount

Styleguide 15.2.2
*/
.ec-orderAccount {
  margin-bottom: 30px;

  p {
    margin-bottom: 0;
  }

  @include clearfix;

  & &__change {
    display: inline-block;
    margin-left: 10px;
    float: right;
  }

  & &__account {
    margin-bottom: 16px;
  }
}

/*
注文詳細 配送情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　配送情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderDelivery

Styleguide 15.2.3
*/
.ec-orderDelivery {
  & &__title {
    padding: 0px;
    font-weight: bold;
    position: relative;
  }

  & &__change {
    display: inline-block;
    /*position: absolute;
    right: 0;
    top: 0;*/
  }

  & &__items {
    @include borderBottom;
    @include borderTop;
  }

  & &__address {
    margin: 10px 0 18px;

    p {
      margin: 0;
    }
  }

  & &__edit {
  }
}

/*
注文履歴詳細 支払情報

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　支払情報(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderPayment
    .ec-rectHeading
      h2 お支払方法
    p 支払方法： 郵便振替

Styleguide 15.2.4
*/
.ec-orderPayment {
}

/*
注文履歴詳細 お問い合わせ

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　お問い合わせ(要ログイン → 詳細を見るボタン押下)](http://demo3.ec-cube.net/mypage)

Markup:
.ec-orderRole
  .ec-orderConfirm
    .ec-rectHeading
      h2 お問い合わせ
    p 記載なし

Styleguide 15.2.5
*/
.ec-orderConfirm {
  margin-bottom: 20px;

  @include media_desktop {
    margin-bottom: 0;
  }

  .ec-input {
    textarea {
      height: 96px;
    }
  }
}

/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。

ex [マイページ　お届け先の複数指定](http://demo3.ec-cube.net/shopping/shipping_multiple)
(商品購入画面 → 「お届け先を追加する」を押下)

Markup:
include /assets/tmpl/elements/15.2.order.pug
+ec-orderAddAddress

Styleguide 15.2.6
*/
.ec-AddAddress {
  padding: 0 10px;

  @include media_desktop {
    margin: 0 10%;
  }

  & &__info {
    margin-bottom: 32px;
    text-align: center;
    font-size: 16px;
  }

  & &__add {
    border-top: 1px solid #f4f4f4;
    padding-top: 20px;
    margin-bottom: 20px;
  }

  & &__item {
    display: table;
    padding: 16px;
    background: #f4f4f4;
    margin-bottom: 16px;
  }

  & &__itemThumb {
    display: table-cell;
    min-width: 160px;
    width: 20%;

    img {
      width: 100%;
    }
  }

  & &__itemtContent {
    display: table-cell;
    vertical-align: middle;
    padding-left: 16px;
    font-size: 16px;
  }

  & &__itemtTitle {
    font-weight: bold;
    margin-bottom: 10px;
  }

  & &__itemtSize {
    margin-bottom: 10px;
  }

  & &__itemtPrice {
  }

  & &__itemtNumber {
  }

  & &__select {
    margin-bottom: 5px;
  }

  & &__selectAddress {
    display: block;

    @include media_desktop {
      display: inline-block;
    }

    label {
      font-size: 16px;
      font-weight: normal;
    }

    select {
      min-width: 100%;

      @include media_desktop {
        min-width: 350px;
      }
    }
  }

  & &__selectNumber {
    display: block;

    @include media_desktop {
      display: inline-block;
      margin-left: 30px;
    }

    label {
      font-size: 16px;
      font-weight: normal;
    }

    input {
      display: inline-block;
      margin-left: 10px;
      width: 80px;
    }
  }

  & &__actions {
    @include media_desktop {
      width: 33.3333%;
      min-width: 250px;
      margin: 0 auto;
    }

    .ec-blockBtn--action {
      margin-bottom: 8px;
    }
  }

  & &__new {
    margin-bottom: 20px;
  }
}

.ec-role .ec-shoppingHeader {
  h1 {
    font-size: 1.5rem;
    margin-bottom: 24px;
    border-bottom: none;
    padding: 0;
  }
}

#page_cart .ec-shopping {
  padding: 0 !important;
}

.ec-shopping {
  padding: 0 20px;
  border-bottom: none;

  .ec-orderAccount {
    border-top: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
    @include media_desktop {
      display: flex;
    }
    @include media-breakpoint-down(md) {
      border-top: none;
      border-bottom: none;
    }
  }
  &__row {
    @include media_desktop {
      display: flex;
      border-top: 1px dotted #ccc;
      border-bottom: 1px dotted #ccc;
      margin-bottom: 0px;
    }
  }

  .ec-rectHeading {
    flex: 30%;
    background: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    /*border-right: 1px solid #ccc;*/
    @include media-breakpoint-down(md) {
      justify-content: initial;
    }
    h2 {
      font-size: 1rem;
      margin: 0;
      @include media-breakpoint-down(md) {
        padding: 8px;
      }
    }
    @include media-breakpoint-down(md) {
      border-right: none;
    }
  }
  .ec-rectDetail {
    flex: 70%;
    margin: 0;

    padding: 20px 16px 20px 16px;
    @include media-breakpoint-down(md) {
      padding: 20px 8px 32px 8px;
    }
    .ec-orderAccount__account {
      margin: 0;
    }
  }
  .ec-orderDelivery__change {
    top: 8px;
  }
  .ec-inlineBtn {
    background: #fff;
    color: #000;
    border-radius: 3px;
    border: 1px solid #ccc;
    font-weight: 500;
  }
  .form-control {
    background: #fff;
  }
  .ec-selects {
    margin-bottom: 0;
    border: none;
  }
  .ec-input textarea {
    font-size: 1rem;
    height: 200px;
  }

  .ec-imageGrid {
    border: none;
  }
  .ec-borderedList li {
    border-bottom: none;
  }
  .ec-cartRow .ec-cartRow__amountColumn {
    width: 14%;
    @include media-breakpoint-down(md) {
      font-size: 14px;
      width: auto;
      div {
        margin-left: 96px;
      }
    }
  }

  &__total {
    text-align: right;
    margin: 30px 20px 60px;
  }
  .ec-cartRole__totalAmount {
    font-size: 1.125rem;
    font-weight: bold;
  }
}
.ec-shopping__actions {
  display: flex;
  justify-content: space-between;
  max-width: 1130px;
  margin: 0 auto;
  margin: 50px auto 70px;
  padding: 0 20px;
  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }

  .ec-blockBtn--cancel {
    margin-bottom: 10px;
    width: 192px;

    color: #000;
    background: #e6e6e6;
    border: none;
    @include media-breakpoint-down(sm) {
      width: calc(50% - 10px);
    }
  }
  .ec-blockBtn--action {
    margin-bottom: 10px;
    width: 192px;
    @include media-breakpoint-down(sm) {
      width: calc(50% - 10px);
    }
  }
}

#page_shopping,
#page_shopping_confirm {
  .ec-orderConfirmInfo {
    border-bottom: #ccc 1px solid;
    margin-bottom: 50px;
  }

  .ec-orderDelivery__info {
    padding: 30px 0;
  }

  .ec-orderDelivery__info .title {
    font-weight: bold;
    padding-bottom: 10px;
  }

  .ec-orderDelivery__actions {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: #ccc 1px solid;
  }

  .ec-orderAccount__account .company-name,
  .ec-orderAccount__account .name,
  .ec-orderDelivery__address .name {
    font-weight: 700;
  }
  .ec-orderAccount__account p,
  .ec-orderDelivery__address p {
    margin-bottom: 10px;
  }

  .ec-orderAccount__account .name,
  .ec-orderDelivery__address .name {
    margin-bottom: 16px;
  }
}
@include media-breakpoint-down(md) {
  .ec-shopping__row {
    margin-bottom: 30px;
  }
}
