@import "../mixins/media";
@import "../mixins/projects";

@mixin pager() {
}
/*
検索・一覧表示

検索欄や、一覧表示に使用するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.2
*/

/*
トピックパス

検索結果で表示されるトピックパスのスタイルです。

ex [商品一覧ページ　横並びリスト部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-topicpath

Styleguide 7.2.1
*/
.ec-searchnavRole__topicpath {
  padding: 16px 0 16px 0px;
  overflow-x: auto;
  margin-left: -32px;
  width: calc(100% + 64px);

  @include media-breakpoint-down(sm) {
    padding: 16px 0 8px 0;

    margin-left: 0px;
    width: 100%;
  }
}
.ec-topicpath {
  letter-spacing: -0.4em;
  border-top: 0;
  border-bottom: 0;
  padding: 0px 32px 8px 32px;
  list-style: none;
  overflow-x: auto;
  font-size: 14px;
  white-space: nowrap;
  padding-left: 32px !important;

  @include media-breakpoint-down(sm) {
    padding-left: 16px !important;
  }
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;

  a {
    //color: #0092c4 !important;
    /*text-decoration: underline !important;*/
    transition: 0.2s !important;
    &:hover {
      color: #0092c4 !important;
      text-decoration: underline !important;
    }
  }

  @include media_desktop {
    border: 0;
    font-size: 14px;
  }

  & &__item {
    @include reset_link();
  }
  & &__divider {
    color: #000;
  }
  & &__item,
  & &__divider,
  & &__item--active {
    display: inline-block;
    min-width: 16px;
    text-align: center;
    position: relative;
    letter-spacing: normal;
    margin: 0 0.15em;
  }
  & &__item--active {
    font-weight: bold;
    @include reset_link();
  }
}

/*
ページャ

検索結果で表示される商品一覧のスタイルです。

ex [商品一覧ページ　ページャ部分](http://demo3.ec-cube.net/products/list?category_id=&name=)

Markup:
include /assets/tmpl/elements/7.2.search.pug
+ec-pager

Styleguide 7.2.2
*/
.ec-pager {
  list-style: none;
  list-style-type: none;
  margin: 0 auto;
  padding: 2em 0;
  text-align: center;
  & &__item,
  & &__item--active {
    display: inline-block;
    min-width: 29px;
    padding: 0 3px 0 2px;
    margin: 0 2px;
    text-align: center;
    position: relative;
    border-radius: 5px;
    border: 1px solid #ccc;
    @include reset_link();
    a {
      color: inherit;
      display: block;
      line-height: 1.8;
      padding: 5px 7px;
      text-decoration: none;
    }
    a:hover {
      color: inherit;
    }
  }
  & &__item--active {
    color: #fff;
    border: none;
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
  }
  & &__item:hover {
    color: #fff;
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
  }
}

.ec-searchnRole-error {
  margin: 35px 0 200px;

  @include media-breakpoint-down(sm) {
    margin: 35px 10px 200px;
  }
}
