@import "../mixins/media";
@import "../mixins/clearfix";

/*
トピック（アイテム2列）

トップページで使用されているトピックのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.5.1
*/

.ec-topicRole {
  padding: 40px 0;
  background: #f8f8f8;

  @include media_desktop {
    padding: 60px 0;
  }

  & &__list {
    display: flex;
    flex-wrap: wrap;

    @include media_desktop {
      flex-wrap: nowrap;
    }
  }
  & &__listItem {
    margin-bottom: 20px;
    width: 100%;
    height: auto;

    @include media_desktop {
      width: calc(100% / 2);

      &:not(:last-of-type) {
        margin-right: 30px;
      }
    }
  }
  & &__listItemTitle {
    margin-top: 0.5em;
    font-size: 14px;
    color: black;

    @include media_desktop {
      margin-top: 1em;
    }
  }
}
