@import "../mixins/media";

/*
標準セクション

通常のコンテナブロックです。

ex [商品詳細ページ　コンテナ](http://demo3.ec-cube.net/products/detail/33)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-roleRole

Styleguide 11.1
*/
.ec-role {
  @include container;
}

/*
マイページセクション

マイページ専用のコンテナブロックです。

ex [マイページ　コンテナ](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/11.1.role.pug
+ec-mypageRole

Styleguide 11.1.2
*/
.ec-mypageRole {
  @include mypageContainer;

  /*↓↓↓マイページは全体的にコンテンツ(.ec-mypageRole)のベーシックなフォントサイズを14pxとします*/
  font-size: 14px;

  .ec-pageHeader h1 {
    @include media_desktop {
      margin: 10px 0 48px;
      padding: 8px 0 18px;
    }
  }
}
.ec-mypage__wrap {
  display: flex;
  @include media-breakpoint-down(lg) {
    display: block;
  }

  .ec-blockBtn--cancel {
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    border: none;
    border-radius: 5px;
    font-weight: 500;
  }
  .ec-delivery {
    width: 100%;
    margin: 0;
  }
}
.ec-mypage__nav {
  position: sticky;
  top: 116px;
  height: 100%;
  @include media-breakpoint-down(lg) {
    position: unset;
    padding: 0 0px;
  }
}
