@import "../mixins/media";

.password-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.password__toggle {
  width: 40px;
  border: none;
  cursor: pointer;
}

/*
パスワードリセット

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [パスワードリセット画面](http://demo3.ec-cube.net/forgot)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/18.1.password.pug
+ec-passwordRole

Styleguide 18.1

*/
.ec-forgotRole {
  @include container;
  & &__intro {
    font-size: 16px;
  }
  & &__form {
    margin-bottom: 16px;
  }
}
