.ec-contact {
  background: #f8f8f8;
  border-radius: 15px;
  text-align: center;
  margin: 80px auto 20px;
  padding: 70px 0;
  a {
    color: #fff;
  }
  &__title {
    h2 {
      font-size: 32px;
      font-weight: bold;
      margin: 0;
    }
    p {
      padding: 40px 18%;
    }
  }
  &__contents {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 60px;
    &__tell {
      p {
        font-size: 28px;
        font-weight: bold;
      }
      small {
        display: block;
        font-size: 12px;
      }
    }
    &__fax {
      p {
        font-size: 28px;
        font-weight: bold;
      }
      small {
        font-size: 12px;
      }
    }
    &__form {
      color: #fff;
      a {
        display: block;
        width: 216px;
        margin: 0 18px;
        background-image: linear-gradient(to right, #4d96cb, #0a7acb);
        border: none;
        border-radius: 5px;
        padding: 12px 20px;
        &:nth-child(2) {
          margin-top: 20px;
        }
      }
    }
  }
}
