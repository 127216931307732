@import "../mixins/media";
@import "../mixins/projects";
/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

ex [トップページ　フッター](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.8.footer.pug
+ec-footerRole

Styleguide 11.3
*/
.ec-footer {
  color: #fff;
  &__top {
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    a {
      color: #fff;
    }
  }

  a {
    display: inline-block;
    color: #fff;
    transition: 0.2s;
    &:hover {
      filter: brightness(0.65);
    }
  }

  .ec-footerRole {
    min-height: 268px;
    background: #222222;
    display: flex;
    justify-content: space-around;
    align-items: center;

    padding: 40px 20px;

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }

    & &__inner {
      @include media_desktop {
        @include container;
      }
    }
    &__center {
      width: 45%;
      line-height: 1.65;

      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
      }

      &__bottom {
        margin-top: 30px;
        div {
          margin: 12px 0;
          a {
            margin-right: 25px;
            color: #fff;
            @include media-breakpoint-down(sm) {
              display: block;
              margin-bottom: 20px;
            }
          }
        }
        @include media-breakpoint-down(md) {
          div {
            a:nth-child(1) {
              margin-left: 25px;
            }
          }
        }
        @include media-breakpoint-down(sm) {
          div {
            a {
              margin-left: 0px;
              margin-right: 0px;
            }
            a:nth-child(1) {
              margin-left: 0px;
            }
          }
        }
      }
    }
    &__right {
      text-align: right;
      &__up {
        icon {
          margin: 0 2px;
        }
      }
      &__bottom {
        margin-top: 30px;
        margin-bottom: 30px;
        text-align: center;

        p {
          a {
            color: #fff;
          }
        }
        small {
          display: block;
          margin-top: 8px;
        }
      }
    }
  }

  /*
  フッターナビ

  フッタープロジェクトで使用するナビゲーション用のコンポーネントです。

  Markup:
  include /assets/tmpl/elements/11.8.footer.pug
  +ec-footerNav

  sg-wrapper:
  <div class="ec-footerRole">
    <div class="ec-footerRole__inner">
      <sg-wrapper-content/>
    </div>
  </div>

  Styleguide 11.3.1
  */
  .ec-footerNavi {
    padding: 0;
    color: white;
    list-style: none;
    text-align: center;

    & &__link {
      display: block;

      @include media_desktop {
        display: inline-block;
      }

      a {
        display: block;
        border-bottom: 1px solid #7d7d7d;
        padding: 15px 0;
        font-size: 14px;
        color: inherit;
        text-decoration: none;

        @include media_desktop {
          display: inline-block;
          border-bottom: none;
          margin: 0 10px;
          padding: 0;
          text-decoration: underline;
        }
      }
      &:hover {
        a {
          opacity: 0.8;
          text-decoration: none;
        }
      }
    }
  }

  /*
  フッタータイトル

  フッタープロジェクトで使用するタイトル用のコンポーネントです。

  Markup:
  include /assets/tmpl/elements/11.8.footer.pug
  +ec-footerTitle

  sg-wrapper:
  <div class="ec-footerRole">
    <div class="ec-footerRole__inner">
      <sg-wrapper-content/>
    </div>
  </div>

  Styleguide 11.3.2
  */
  .ec-footerTitle {
    padding: 40px 0 60px;
    text-align: center;
    color: white;

    @include media_desktop {
      padding: 50px 0 80px;
    }

    & &__logo {
      display: block;
      margin-bottom: 10px;
      font-weight: bold;
      @include reset_link();

      a {
        font-size: 22px;
        color: inherit;
        @include media_desktop {
          font-size: 24px;
        }
      }

      &:hover {
        a {
          opacity: 0.8;
          text-decoration: none;
        }
      }
    }
    & &__copyright {
      font-size: 10px;

      @include media_desktop {
        font-size: 12px;
      }
    }
  }
}
