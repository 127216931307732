@import "../mixins/projects";
@import "../mixins/media";

/*
注文履歴詳細

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　注文詳細](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailRole

Styleguide 16.2
*/

/*
注文履歴詳細 メール履歴

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMail

Styleguide 16.2.5
*/
.ec-orderMails {
  & &__item {
    padding-bottom: 10px;
    @include borderBottom();
  }
  & &__time {
    margin: 0;
  }
  & &__body {
    display: none;
  }
}

/*
注文履歴詳細 メール履歴個別

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

ex [マイページ　メール履歴個別](http://demo3.ec-cube.net/mypage)
(要ログイン → 詳細を見るボタン押下)

Markup:
include /assets/tmpl/elements/16.2.historyDetail.pug
+ec-historyDetailMailHistory

Styleguide 16.2.6
*/
.ec-orderMail {
  padding-bottom: 10px;
  @include borderBottom();
  margin-bottom: 16px;
  & &__time {
    margin: 0;
  }
  & &__body {
    display: none;
  }
  & &__time {
    margin-bottom: 4px;
  }
  & &__link {
    a {
      color: #0092c4;
      text-decoration: none;
      cursor: pointer;
    }
    a:hover {
      color: #33a8d0;
    }
    margin-bottom: 4px;
  }
  & &__close {
    a {
      color: #0092c4;
      text-decoration: none;
      cursor: pointer;
    }
    a:hover {
      color: #33a8d0;
    }
  }
}
