@import "../mixins/_projects";
$padding-base-vertical: 6px !default;
$padding-base-horizontal: 12px !default;

$btn-primary-bg: #5cb1b1;
$btn-primary-color: #fff;
$btn-action-bg: #de5d50;
$btn-action-color: #fff;
$btn-cancel-bg: #525263;
$btn-cancel-color: #fff;
$btn-default-bg: #f5f7f8;
$btn-default-color: #525263;
$btn-default-border: #ccc !default;

$btn-border-radius-base: 0px;
$cursor-disabled: not-allowed !default;

@mixin _btn($color, $background, $border) {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  @include button-size(
    $padding-base-vertical,
    $padding-base-horizontal,
    $font-size-base,
    $line-height-base
  );
  @include user-select(none);
  padding: 10px 16px;
  text-decoration: none;

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
      box-shadow: none !important;
      outline: unset;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0, 0, 0, 0.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    @include opacity(0.65);
    @include box-shadow(none);
  }

  @include button-variant(
    $background,
    $border,
    $color,
    $disabled-color: $color
  );
  // [converter] extracted a& to a.btn

  .ec-icon img {
    width: 1em;
    vertical-align: text-bottom;
  }
}

@mixin btn-default() {
  @include _btn($btn-default-color, $btn-default-bg, $btn-default-border);
}
@mixin btn-action() {
  @include _btn($btn-action-color, $btn-action-bg, $btn-action-bg);
  background-image: linear-gradient(to right, #4d96cb, #0a7acb);
  border: none;
  font-weight: 500;
  border-radius: 3px;
  padding: 13px 16px;
  &:hover,
  &:active {
    background-image: linear-gradient(
      to right,
      rgba(77, 150, 203, 0.8),
      rgba(10, 122, 203, 0.8)
    );
  }
}
@mixin btn-cancel() {
  @include _btn($btn-cancel-color, $btn-cancel-bg, $btn-cancel-bg);
  background: #fff;
  color: #000;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-weight: 500;
  padding: 13px 16px;
  &:hover,
  &:active {
    color: #000;
    background: rgba(0, 0, 0, 0.1);
    border-color: #ccc;
  }
}
@mixin btn-primary() {
  @include _btn($btn-primary-color, $btn-primary-bg, $btn-primary-bg);
}

@mixin blockBtn-default() {
  @include _btn($btn-default-color, $btn-default-bg, $btn-default-border);
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}
@mixin blockBtn-action() {
  @include _btn($btn-action-color, $btn-action-bg, $btn-action-bg);
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
  background-image: linear-gradient(to right, #4d96cb, #0a7acb);
  border: none;
  font-weight: 500;
  border-radius: 3px;
  &:hover,
  &:active {
    background-image: linear-gradient(
      to right,
      rgba(77, 150, 203, 0.8),
      rgba(10, 122, 203, 0.8)
    );
  }
  &:focus,
  &:active {
    box-shadow: none;
  }
}
@mixin blockBtn-cancel() {
  @include _btn($btn-cancel-color, $btn-cancel-bg, $btn-cancel-bg);
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
  background: #fff;
  color: #000;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-weight: 500;
  &:hover,
  &:active {
    color: #000;
    background: rgba(0, 0, 0, 0.1);
    border-color: #ccc;
  }
  &:focus,
  &:active {
    color: #000;
    background-color: unset;
    border-color: unset;
    box-shadow: none;
    outline: unset;
    outline-offset: unset;
  }
}
@mixin blockBtn-primary() {
  @include _btn($btn-primary-color, $btn-primary-bg, $btn-primary-bg);
  display: block;
  width: 100%;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}

// User select
// For selecting text on the page

@mixin user-select($select) {
  -webkit-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select; // IE10+
  user-select: $select;
}

@mixin linkBtn {
  &.disabled,
  fieldset[disabled] & {
    pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
  }
}
