@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/clearfix";
@import "../mixins/animation";

.ec-sideNav {
  color: #333;
  position: sticky;
  top: 140px;
  &__sideItem {
    display: block;
    border-radius: 0.5rem;
    padding: 10px 0;
    width: 75px;
    min-width: 75px;
    height: 75px;
    margin: 20px auto 10px;
    transition: 0.2s;

    &.order_cart {
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    }
    &.estimate_cart {
      background-image: linear-gradient(to right, #db9919, #db6e1d);
    }

    &.order_cart,
    &.estimate_cart {
      &:hover {
        filter: brightness(1.15);
      }
      &:hover img,
      &:hover .white {
        transform: scale(1.05, 1.05);
      }
    }

    /*以下.grayWrap用*/
    &.grayWrap {
      margin: 0 auto;
      transition: 0.2s;

      &:hover {
        transform: scale(1.075, 1.075);
      }

      .gray {
        color: #444;
      }
      img {
        filter: brightness(0) saturate(100%) invert(34%) sepia(0%) saturate(0%)
          hue-rotate(280deg) brightness(95%) contrast(97%);
      }
      &:hover .gray {
        color: #777;
      }
      &:hover img {
        filter: brightness(0) saturate(100%) invert(53%) sepia(0%)
          saturate(1517%) hue-rotate(142deg) brightness(86%) contrast(77%);
      }
    }
  }
  & &__icon {
    margin: 0 auto;
    display: block;
    padding: 5px 0;
    text-align: center;
    transition: 0.2s;
  }
  & &__text {
    text-align: center;
    transition: 0.2s;
    white-space: nowrap;
    &.white {
      color: #fff;
      font-size: 12px;
      margin: 2px 0;
    }
    &.gray {
      color: #444444;
      font-size: 12px;
      margin: 0;
    }
  }
}
