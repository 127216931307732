@import "../mixins/media";
@import "../mixins/btn";

/*
その他のボタン

通常のボタンや、アイコンボタン以外のボタンを定義します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.3
*/

/*
ページトップボタン

ページトップボタンを表示します

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
.ec-blockTopBtn

Styleguide 2.3.1
*/
.ec-blockTopBtn {
  display: none;
  position: fixed;
  width: 120px;
  height: 40px;
  right: 0;
  bottom: 10px;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  opacity: 0.8;
  background-color: #9da3a9;

  @include media_desktop {
    right: 30px;
    bottom: 30px;
  }
}

.ec-free_word {
  text-align: right;

  &__button {
    display: inline-block;
    margin: 0 8px;
    font-size: 14px;
    color: #fff;
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    border: 1px solid #fff;
    border-radius: 0.5rem;
    padding: 7px 15px;
    transition: 0.2s;

    &:hover {
      filter: brightness(1.15);
      color: #ffffff;
    }
  }
}
