@import "../mixins/media";
/*
会員登録

新規会員登録 に関する Project コンポーネントを定義します。

ex [新規会員登録画面　会員登録](http://demo3.ec-cube.net/entry)

Markup:
include /assets/tmpl/elements/19.1.register.pug
+ec-registerRole

Styleguide 19.1

*/
.ec-registerRole {
  @include container;
  & &__actions {
    padding-top: 20px;
    text-align: center;
    p {
      margin-bottom: 16px;
    }
    @include media_desktop {
      margin: 40px 0;
    }
  }
  .ec-blockBtn--action {
    margin-bottom: 16px;
    color: #fff;
  }
}
.ec-RegisterRole__actions {
  .ec-blockBtn--action {
    color: #fff;
  }
  .ec-blockBtn--cancel {
    color: #fff;
  }
  @include media_desktop {
    margin: 40px 0;
  }
}
.ec-registerCompleteRole {
  @include container;
  margin: 100px auto;
}
