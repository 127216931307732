@import "../mixins/media";
@import "../mixins/projects";
@import "../mixins/clearfix";
@import "../mixins/animation";

/*
ヘッダー

ヘッダー用のプロジェクトコンポーネントを提供します。

ex [トップページ　ヘッダー](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/11.2.header.pug
include /assets/tmpl/elements/11.3.headerNavi.pug
include /assets/tmpl/elements/11.4.categoryNavi.pug
+b.ec-layoutRole
  +e.header
    +ec-headerRole
    +ec-headerNaviRole
    +ec-categoryNaviRole

Styleguide 11.2
*/
.ec-layoutRole {
  display: flex;
  width: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  transition: transform 0.3s;
  background: #fff;
  margin-top: 116px;
  margin-top: 116px;
  @include media-breakpoint-down(md) {
    margin-top: 56px;
  }
  & &__contentTop {
    padding: 0;
  }

  & &__contents {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    padding: 30px;
  }
  & &__main {
    width: 100%;
  }
  & &__flexLeft {
    width: calc(100% - 120px);
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  & &__flexRight {
    width: 120px;
    background: #f8f8f8;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  & &__flexLeft {
    width: calc(100% - 120px);
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
  & &__mainWithColumn {
    width: 100%;
    @include media_desktop() {
      width: 75%;
    }
  }
  & &__mainBetweenColumn {
    width: 100%;
    @include media_desktop() {
      width: 50%;
    }
  }
  & &__left,
  & &__right {
    display: none;
    @include media_desktop() {
      display: flex;
      width: 25%;
    }
  }
}

.ec-layoutRole {
  & &__contents.front_page {
    padding: 0px;
  }
}

.ec-headerRole {
  @include container;
  padding-top: 15px;
  position: relative;
  margin-top: 116px;
  margin-top: 116px;
  &:after {
    display: none;
  }
  @include media_desktop {
    @include clearfix;
  }
  &::before {
    display: none;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: auto;
  @include media_desktop {
    width: 100%;
    @include clearfix;
  }
  & &__title {
    width: 100%;
  }
  & &__navSP {
    display: block;
    position: absolute;
    top: 15px;
    width: 27%;
    right: 0;
    text-align: right;
    @include media_desktop {
      display: none;
    }
  }
}

.ec-headerNaviRole {
  @include container;
  max-width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 116px;
  position: fixed;
  top: 0px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 100;
  backdrop-filter: blur(5px);
  @include media-breakpoint-down(md) {
    height: 56px;
    padding-right: 0;
    padding-left: 8px;
  }

  .fa-bars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include media_desktop {
    // padding-bottom: 40px;
  }

  & &__left {
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    @include media-breakpoint-down(md) {
      width: 30%;
    }
  }

  & &__search {
    display: none;
    @include media_desktop() {
      width: 100%;
      flex: 1;
      display: inline-block;
      margin: 0 24px;
      @include reset_link;
    }
  }
  & &__navSP {
    display: block;
    @include media_desktop() {
      display: none;
      @include reset_link;
    }
  }

  & &__right {
    width: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  & &__nav {
    display: inline-block;
    @include reset_link;
  }
  & &__cart {
    display: inline-block;
    @include reset_link;
  }
}

.ec-headerRole__navSP {
  display: none;
  @include media-breakpoint-down(md) {
    display: flex;
    justify-content: end;
    width: 36%;
    height: 100%;
    margin: 0 0 0 auto;
  }
  .search-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
    background-color: #ffffff00;
    cursor: pointer;

    transition: 0.2s;
    &:hover {
      filter: brightness(0.95);
      background-color: #ffffff;
      color: #fff;
    }

    div {
      width: 65%;
      margin: 3px auto;
      padding-top: 2px;
    }
    p {
      color: #358ccb;
      font-size: 0.5rem;
      white-space: nowrap;
    }
  }
}

.ec-headerNavSP {
  display: block;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: white;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000;

  .fas {
    vertical-align: top;
  }

  @include media_desktop {
    display: none;
  }
}
.ec-headerNavSP.is-active {
  display: none;
}

.spMenu {
  border-radius: unset;
  width: 56px;
  height: 100%;
  position: unset;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to right, #4d96cb, #0a7acb);

  transition: 0.2s;
  &:hover {
    filter: brightness(1.15);
  }

  .fa-bars {
    position: unset;
    transform: unset;
  }
}
.hambuger_menu_icon {
  position: relative;
  width: 60px;
  height: 2px;
  color: #000;
  background: currentColor;
  margin-top: 25px 10px;
}

.hambuger_menu_icon:before {
  content: "";
  position: absolute;
  top: -15px;
  left: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
}

.hambuger_menu_icon:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
}

/*
ヘッダー：タイトル

ヘッダー内で使用されるタイトルコンポーネントです。

Markup:
include /assets/tmpl/elements/11.2.header.pug
+ec-headerTitle

Styleguide 11.2.1
*/
.ec-headerTitle {
  @include commonStyle();
  & &__title {
    text-align: center;
    h1 {
      margin: 0;
      padding: 0;
    }
    a {
      display: inline-block;
      margin-bottom: 30px;
      text-decoration: none;
      font-size: 20px;

      @include media_desktop() {
        font-size: 40px;
      }
      font-weight: bold;
      color: black;

      &:hover {
        opacity: 0.8;
      }
    }
  }
  & &__subtitle {
    font-size: 10px;
    text-align: center;
    @include media_desktop() {
      font-size: 16px;
      margin-bottom: 10px;
    }
    a {
      display: inline-block;
      color: #0092c4;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

.bg-color {
  color: #fff;
  background-image: linear-gradient(to right, #4d96cb, #0a7acb);
}
.navbar-toggler {
  border: none;
  &:focus {
    display: none;
  }
}
.ec-headerNaviRole__spNav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-image: linear-gradient(to right, #4d96cb, #0a7acb);
}
.hambuger_menu_icon {
  position: relative;
  height: 3px;
  color: #fff;
  background: currentColor;
  margin: 6px;
}

.hambuger_menu_icon:before {
  content: "";
  position: absolute;
  top: -12px;
  width: 100%;
  height: 100%;
  background: currentColor;
}

.hambuger_menu_icon:after {
  content: "";
  position: absolute;
  top: 12px;
  width: 100%;
  height: 100%;
  background: currentColor;
}

/*
ヘッダー：ユーザナビゲーション

ヘッダー内でユーザに関与するナビゲーションコンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__nav`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerNav

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__nav">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.3
*/
.ec-headerNav {
  text-align: right;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(md) {
    text-align: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & &__item {
    margin-left: 0;
    display: inline-block;
    @include media-breakpoint-down(md) {
      display: block;
    }
    a {
      transition: 0.2s;
      &:hover {
        filter: brightness(1.15);
      }
    }
  }
  .username,
  .companyname {
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
  }
  & &__itemIcon {
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 18px;
    color: black;
    @include media_desktop {
      margin-right: 0;
      font-size: 20px;
    }
  }
  & &__itemLink {
    display: inline-block;
    margin: 0 0 0 20px;
    font-size: 14px;
    vertical-align: middle;
    color: black;
    color: #fff;
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    border-radius: 4px;
    padding: 7px 14px;
    white-space: nowrap;
  }
}

/*
ヘッダー：ロゴブロック
*/
.ec-headerNaviRole__logo {
  width: fit-content;
  img.ec-headerNaviRole__logo__image {
    width: 185px !important;
    max-width: 185px !important;
    filter: brightness(1.08);
    @include media-breakpoint-down(lg) {
      width: 150px !important;
      max-width: 150px !important;
    }
  }
  a {
    transition: 0.2s;
    &:hover {
      filter: brightness(1.13);
    }
  }
}

/*
ヘッダー：検索ボックス

ヘッダー内で使用される商品検索コンポーネントです。
<br><br>
`.ec-headerNaviRole`>`.ec-headerNaviRole__search`内に記述すると２カラム上の右側に配置することができます。

Markup:
include /assets/tmpl/elements/11.3.headerNavi.pug
+ec-headerSearch

sg-wrapper:
<div class="ec-headerNaviRole">
  <div class="ec-headerNaviRole__search">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.2.4
*/
.ec-headerSearch {
  @include clearfix;
  & &__wrap {
    display: flex;
  }
  & &__category {
    float: none;
    @include media_desktop {
      position: relative;
      width: fit-content;
    }
    .ec-select {
      overflow: hidden;
      width: fit-content;
      margin: 0;
      text-align: center;

      select {
        width: 9em;
        cursor: pointer;
        padding: 8px 24px 8px 16px;
        text-indent: 0.01px;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        background: transparent;
        background-image: none;
        box-shadow: none;
        appearance: none;
        color: #000;
        font-size: 0.875rem;

        @include media_desktop {
          height: 38px;
        }

        option {
          color: #000;
        }

        &::-ms-expand {
          display: none;
        }
      }

      &.ec-select_search {
        position: relative;
        border: 1px #cccccc solid;
        border-right: 0;
        background: #efefef;
        color: #fff;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 4px 0;
        transition: 0.2s;
        &:hover {
          filter: brightness(0.95);
        }

        &::before {
          position: absolute;
          top: 1.2em;
          right: 0.4em;
          width: 0;
          height: 0;
          padding: 0;
          content: "";
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #000;
          pointer-events: none;
        }
      }
    }
  }
  & &__keyword {
    position: relative;
    color: $clrDarkGray;
    border: 1px solid #ccc;
    background-color: #f6f6f6;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 8px 0;

    @include media_desktop {
      width: 100%;
      border-bottom-left-radius: inherit;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    input[type="search"] {
      width: 100%;
      height: 30px;
      font-size: 0.875rem;
      border: 0 none;
      padding: 0 42px 0 1em;
      box-shadow: none;
      background: none;
      box-sizing: border-box;
      margin-bottom: 0;
      &:focus,
      &:-webkit-autofill {
        background-color: #f6f6f6;
        box-shadow: 0 0 0px 999px #f6f6f6 inset;
      }
    }
    .ec-icon {
      width: 36px;
      height: 20px;
      text-align: center;
      margin: 0;
    }
  }
  & &__keywordBtn {
    cursor: pointer;
    border: none;
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    color: #fff;
    position: absolute;
    width: 3em;
    height: 48px;
    right: -1px;
    top: -1px;
    outline: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: 0.2s;
    &:hover {
      filter: brightness(1.15);
    }
  }
}

/*
ヘッダー：カテゴリナビ

ヘッダー内で使用されている商品のカテゴリ一覧として使用します。
`li`の中に`ul > li`要素を入れることで、階層を深くする事ができます。

Markup:
include /assets/tmpl/elements/11.4.categoryNavi.pug
+ec-itemNav

sg-wrapper:
<div class="ec-categoryNaviRole" style="padding-bottom:150px;">
  <sg-wrapper-content/>
</div>

Styleguide 11.2.5
*/
.ec-categoryNaviRole {
  @include container;
  display: none;
  @include media_desktop() {
    display: block;
    width: 100%;
    @include reset_link;
  }
}

.ec-itemNav {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

.ec-itemNav__nav {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: auto;
  height: auto;
  list-style-type: none;
  text-align: center;
  vertical-align: bottom;
  @include media_desktop {
    display: inline-block;
  }
}

.ec-itemNav__nav li {
  float: none;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  position: relative;
  @include media_desktop {
    float: left;
    width: auto;
  }
}

.ec-itemNav__nav li a {
  display: block;
  border-bottom: 1px solid #e8e8e8;
  margin: 0;
  padding: 16px;
  height: auto;
  color: #2e3233;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-decoration: none;
  text-align: left;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  @include media_desktop {
    text-align: center;
    border-bottom: none;
  }
}

.ec-itemNav__nav li ul {
  display: none;
  z-index: 0;
  margin: 0;
  padding: 0;
  min-width: 200px;
  list-style: none;
  position: static;
  top: 100%;
  left: 0;
  @include media_desktop {
    display: block;
    z-index: 100;
    position: absolute;
  }
}

.ec-itemNav__nav li ul li {
  overflow: hidden;
  width: 100%;
  height: auto;
  transition: 0.3s;
  @include media_desktop {
    overflow: hidden;
    height: 0;
  }
}

.ec-itemNav__nav li ul li a {
  border-bottom: 1px solid #e8e8e8;
  padding: 16px 22px 16px 16px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: left;
  background: black;
}

.ec-itemNav__nav > li:hover > a {
  background: #fafafa;
}

.ec-itemNav__nav > li:hover li:hover > a {
  background: #333;
}

.ec-itemNav__nav > li:hover > ul > li {
  @include media_desktop {
    overflow: visible;
    height: auto;
  }
}

.ec-itemNav__nav li ul li ul {
  top: 0;
  left: 100%;
  width: auto;
}

.ec-itemNav__nav li ul li ul:before {
  @include media_desktop {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: white;
    position: absolute;
    top: 19px;
    right: auto;
    left: -20px;
  }
}

.ec-itemNav__nav li ul li:hover > ul > li {
  @include media_desktop {
    overflow: visible;
    height: auto;
    width: auto;
  }
}

.ec-itemNav__nav li ul li ul li a {
  background: #7d7d7d;
}

.ec-itemNav__nav li:hover ul li ul li a:hover {
  background: #333;
}

/*
ヘッダー：SPヘッダー

SP時のみ出現するヘッダーに関係するコンポーネントです。<br>
ex [トップページ](http://demo3.ec-cube.net/)画面サイズが768px以下に該当。<br>
<br>
`.ec-drawerRole`：SPのドロワー内の要素をwrapするコンポーネントです。<br>
`.ec-headerSearch`、`.ec-headerNav`、`.ec-itemNav`は`.ec-drawerRole`の子要素にある場合、ドロワーに適したスタイルに変化します。<br><br>
`.ec-overlayRole`：SPのドロワー出現時にz-indexがドロワー以下の要素に半透明の黒背景をかぶせるコンポーネントです。<br>

Styleguide 11.2.6
*/

.ec-drawerRole {
  background: #e6e6e6;
  width: 100%;
  height: 100dvh;
  transform: translateX(767px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.3s;
  @include media_desktop() {
    display: none;
  }

  .ec-headerSearchArea {
    padding: 20px 10px;
    width: 100%;
    background: #f8f8f8;
  }

  .ec-headerSearch {
    padding: 16px 8px 26px;
    background: #ebebeb;
    color: #636378;
    select {
      width: 100% !important;
    }
  }

  .ec-headerCategoryArea {
    .ec-headerCategoryArea__heading {
      border-top: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
      padding: 1em 10px;
      font-size: 16px;
      font-weight: bold;
      color: black;
      background: #f8f8f8;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .ec-itemNav__nav li a {
      border-bottom: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      color: black;
      font-weight: normal;
      background: #f8f8f8;
    }

    .ec-itemNav__nav li ul li a {
      border-bottom: 1px solid #ccc;
      padding-left: 20px;
      font-weight: normal;
      background: white;
    }

    .ec-itemNav__nav > li:hover > a {
      background: #f8f8f8;
    }

    .ec-itemNav__nav > li:hover li:hover > a {
      background: white;
    }

    .ec-itemNav__nav li ul li ul li a {
      padding-left: 40px;
      color: black;
      background: white;
    }

    .ec-itemNav__nav li:hover ul li ul li a:hover {
      background: white;
    }

    .ec-itemNav__nav li ul li ul li ul li a {
      padding-left: 60px;
      font-weight: normal;
    }
  }
  .ec-headerLinkArea {
    height: 85%;
    background: #fff;
    margin: 20px;
    border-radius: 15px;
    overflow-y: auto;

    .ec-headerLink__carts {
      .ec-sideNav {
        display: flex;
        padding: 30px 20px;
        .gray {
          background: #8b8b8b;
          img {
            padding: 2px 0 1px;
          }
        }
      }
      .ec-sideNav__sideItem {
        margin: 0;
        margin-right: 20px;
      }
    }

    .ec-headerLink__list {
      border-top: 1px solid #ccc;
      width: calc(100% - 24px);
      margin: 0 12px;
      margin-bottom: 70px;
    }

    a.ec-headerLink__item {
      display: block;
      border-bottom: 1px solid #ccc;
      padding: 15px 0px;
      font-size: 16px;
      color: #333;
      transition: 0.2s;
      &:hover {
        color: #999;
        background-color: #f6f6f6;
        .ec-headerLink__icon {
          opacity: 0.7;
        }
      }
    }
    .ec-headerLink__itemWrap {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 17px;
      span {
        margin-left: 0px;
        flex: 1;
      }
    }
    .ec-headerLink__icon {
      width: 40px;
      text-align: center;
      transition: 0.2s;
      filter: brightness(0) saturate(100%) invert(34%) sepia(0%) saturate(0%)
        hue-rotate(280deg) brightness(95%) contrast(97%);
    }
  }
}

.ec-spHeaderNaviRole {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  &__login {
    width: 100%;
    padding: 0px 12px 0px 20px;
  }

  &__close {
    position: relative;
    background: #8b8b8b;
    width: 56px;
    height: 56px;

    transition: 0.2s;
    &:hover {
      filter: brightness(1.15);
      color: #fff;
    }

    .ec-drawerRoleClose {
      display: block;
      width: 56px;
      height: 56px;
    }
    .ec-drawerRoleClose::before,
    .ec-drawerRoleClose::after {
      content: "";
      position: absolute;
      top: 27px;
      left: 50%;
      width: 2px;
      height: 30px;
      background: #ffffff;
    }

    .ec-drawerRoleClose::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .ec-drawerRoleClose::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.ec-drawerRoleClose {
  display: none;
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
  width: 40px;
  font-size: 18px;
  text-align: center;
  color: black;
  background: #8b8b8b;
  top: 10px;
  left: 270px;
  z-index: 1000;

  .fas {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include media_desktop {
    display: none;
  }
}

.ec-drawerRole.is_active {
  display: block;
  transform: translateX(0);
  transition: all 0.3s;
  z-index: 100000;

  @include media_desktop() {
    display: none;
  }
}

.ec-drawerRoleClose.is_active {
  display: inline-block;
  transition: all 0.3s;

  @include media_desktop {
    display: none;
  }
}

.ec-overlayRole {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  background: transparent;
  transform: translateX(0);
  transition: all 0.3s;
  visibility: hidden;

  @include media_desktop {
    display: none;
  }
}

.have_curtain .ec-overlayRole {
  display: block;
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  visibility: visible;

  @include media_desktop {
    display: none;
  }
}

.ec-spSearchHeader {
  height: 56px;
  display: flex;
  justify-content: space-between;
  background: #f8f8f8;
  &__title {
    padding: 20px 0 20px 20px;
    p {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}

.ec-searchDrawerRole {
  background: black;
  width: 100%;
  transform: translateX(767px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  transition: all 0.3s;
  @include media_desktop() {
    display: none;
  }
  .ec-spFilter {
    border-radius: 0;
    height: 100vh;
    padding: 0;
    background: #fff;
    .ec-spFilter__topWrap {
      overflow-y: auto;
      max-height: 100vh;
      padding-bottom: 55%;
      div {
        padding: 20px;
      }
      .filterClear {
        font-size: 0.875rem;
        font-weight: 700;
      }
      .searchWrap {
        position: relative;
        padding: 0 20px;
        button {
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;
        }
      }
      .searchInput {
        width: 100%;
        height: 42px;
        display: block;
        margin: 0 auto;
        padding: 0 10px;
        border: 1px solid #ccc;
        border-radius: 7px;
        box-shadow: 0px 3px 5px #ccc;
        &:focus {
          outline: none;
        }
      }
      .searchIcon {
        position: absolute;
        top: 14px;
        right: 35px;
      }

      .rangeWrap {
        padding: 0;
        margin: 20px 0;
      }
      input[type="range"] {
        width: 100%;
      }
      .price {
        width: 30%;
        padding: 12px;
        border: 1px solid #ccc;
      }
    }
    .ec-spFilter__bottomWrap {
      position: fixed;
      bottom: 0%;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 15px;
      background: #f8f8f8;
      span {
        font-size: 1.25rem;
        font-weight: 600;
      }
      .filter {
        width: 47%;
        color: #fff;
        background-image: linear-gradient(to right, #4d96cb, #0a7acb);
        padding: 15px 20px;
        border: none;
        border-radius: 5px;
      }
    }

    input {
      margin-right: 5px;
    }
    ul {
      list-style: none;
      padding: 0;
    }
    li:nth-child(1) {
      border-top: 1px solid #ccc;
    }
    li {
      padding: 15px 0;
      border-bottom: 1px solid #ccc;
    }
    &__title {
      font-size: 1.125rem;
      font-weight: bold;
      padding: 0 0 20px;
    }
    &__subtitle {
      font-size: 1rem;
      font-weight: bold;
      padding: 15px 0px;
    }
    &__number {
      padding: 15px 0 0;
    }
    .ec-spFilter__rangeWrap
      .ui-slider.ui-corner-all.ui-slider-horizontal.ui-widget.ui-widget-content {
      background: #f8f8f8;
      height: 1px;
      padding: 4px;
    }
    #sp-price-range .ui-widget-header {
      background: #0975ff;
      border: none;
      height: 1px;
      padding: 4px;
    }
  }
}

.ec-searchDrawerRole.is_active {
  display: block;
  transform: translateX(0);
  transition: all 0.3s;
  z-index: 100000;
  background: #e6e6e6;
  height: 100dvh;

  @include media_desktop() {
    display: none;
  }
}

.ec-searchDrawerRole.is_active {
  display: inline-block;
  transition: all 0.3s;

  @include media_desktop {
    display: none;
  }
}

/*
ヘッダー：test

test

Markup:
span.ec-itemAccordionParent test1
ul.ec-itemNavAccordion
  li.ec-itemNavAccordion__item
    a(href='') test2
    ul.ec-itemNavAccordion
      li.ec-itemNavAccordion__item
        a(href='') test3
        ul.ec-itemNavAccordion
          li.ec-itemNavAccordion__item
            a(href='') test4

Styleguide 11.2.7
*/

.ec-itemNavAccordion {
  display: none;
}

.ec-maintenanceAlert {
  background: steelblue;
  height: 50px;
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  z-index: 9999;
  display: flex;
  font-weight: bold;
  & > * {
    margin: auto;
  }
  & &__icon {
    display: inline-block;
    margin-right: 1rem;
    width: 20px;
    height: 20px;
    color: #fff;
    fill: #fff;
    vertical-align: top;
  }
  & + * {
    margin-top: 50px;
  }
}

.Android .ec-drawerRole {
  height: 100%;
}
