.ec-newItem {
  margin: 80px auto;
  &__title {
    h2 {
      font-weight: bold;
      margin: 30px 0 12px 0;
      padding: 0 24px;
    }
  }
}
