@import "../mixins/media";
/*
退会手続き

退会手続きで使用するページコンポーネントです。

ex [退会手続き](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawRole

Styleguide 21.1

*/
.ec-withdrawRole {
  @include container;
  text-align: center;
  padding: 0 16px;
  margin: 50px 0 140px;
  & &__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px;
  }
  & &__description {
    margin-bottom: 32px;
    font-size: 16px;
  }
  .ec-icon {
    img {
      width: 100px;
      height: 100px;
    }
  }
}

/*ボタン用*/
.ec-withdrawRole {
  .ec-blockBtn--cancel {
    color: #ee4e4e;
    background-color: #f5f7f8;
    border: #bbb 1px solid;
    background-image: none;
    font-weight: bold;
    border-radius: 5px;
  }
}
/*ボタン用*/
.ec-withdrawConfirmRole {
  .ec-blockBtn--cancel {
    color: #337ce8;
    background-color: #f5f7f8;
    border: #bbb 1px solid;
    background-image: none;
    font-weight: bold;
    border-radius: 5px;
  }
  .ec-blockBtn {
    color: #ee4e4e;
    border-radius: 5px;
  }
}

/*
退会手続き実行確認

退会手続き実行確認で使用するページコンポーネントです。

ex [退会手続き　退会手続きへボタン→押下](http://demo3.ec-cube.net/mypage/withdraw)

Markup:
include /assets/tmpl/elements/21.1.withdraw.pug
+ec-withdrawConfirm

Styleguide 21.1.2

*/
.ec-withdrawConfirmRole {
  margin: 50px 0 140px;
  & &__cancel {
    margin-bottom: 20px;
  }
  & &__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px;
  }
  & &__description {
    margin-bottom: 32px;
    font-size: 16px;
  }
  .ec-icon {
    img {
      width: 100px;
      height: 100px;
    }
  }
}

.ec-withdrawCompleteRole {
  margin: 100px 0 140px;
}
