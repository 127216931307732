.ec-recommend {
  margin: 80px auto;

  &__title {
    h2 {
      font-weight: bold;
      margin: 30px 0 12px 0;
      padding: 0 24px;
    }
  }

  &__wrap {
    &__inner {
      overflow-x: scroll;
      margin: 15px 0 4px 0;
      padding: 0 20px;

      &__categoryList {
        display: flex;
        padding: 0;
        margin: 15px 0;
        width: max-content;

        li {
          list-style: none;
        }

        &__button {
          display: inline-block;
          margin: 0;
          font-size: 14px;
          color: #525263;
          background-color: #f6f6f6;
          border-radius: 0.5rem;
          padding: 10px 20px;
          margin: 0 5px;

          &.active {
            color: #fff;
            background-image: linear-gradient(to right, #4d96cb, #0a7acb);
          }
        }
      }
    }
  }
}
