@import "../mixins/projects";
@import "../mixins/media";
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/

/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole {
  & &__navlist {
    @include reset_link;
    width: 235px;
    display: block;
    flex-wrap: wrap;
    margin-bottom: 32px;
    padding: 0;
    list-style: none;
    margin-top: 32px;

    @include media_desktop {
      flex-wrap: nowrap;
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 12px;
    }
  }

  & &__item {
    display: flex;
    width: 100%;
    border-color: #d0d0d0;
    border-style: solid;
    border-width: 0 1px 0px 0;
    text-align: left;
    transition: 0.2s;
    &:hover {
      background: #f5f7f8;
    }
    a {
      margin: auto;
      padding: 16px 8px;
      width: 100%;
      display: inline-block;
      transition: 0.2s;
    }
    @include media-breakpoint-down(lg) {
      width: calc((100% / 3) - 8px);
      margin: 4px;
      border-radius: 4px;
      border: #cccccc 1px solid;
      background-color: #eaeaea;
      text-align: center;
      font-weight: bold;
      a {
        padding: 12px 8px;
      }
    }
    @include media-breakpoint-down(md) {
      font-size: 13px;
    }
    @include media-breakpoint-down(sm) {
      width: calc((100% / 2) - 8px);
    }
  }
  .active {
    a {
      border-right: 2px solid #358ccb;
      background: #f5f7f8;
      font-weight: bold;
    }
    @include media-breakpoint-down(lg) {
      border: #358ccb 1px solid;
      background-color: #ffffff;
      color: #358ccb;
      a {
        border-right: none;
      }
    }
  }
}

body.mypage .ec-layoutRole__main {
  @include media-breakpoint-down(lg) {
    padding-bottom: 20px;
  }
}
.ec-mypageRole .ec-pageHeader h1 {
  @include media-breakpoint-down(md) {
    font-size: 24px;
    border: none;
  }
}

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/

/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg {
  @include mypageContainer;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  @include borderBottom;
}

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.favorite {
  .ec-layoutRole__main {
    max-width: 1216px;
    margin: 0 auto;
  }

  .ec-mypageHeader h1 {
    margin: 60px 0 30px;
    font-weight: bold;
  }

  #toggle {
    display: none;
    cursor: pointer;
    z-index: 99999;
  }

  .menu,
  .itemmenu {
    display: none;
    position: absolute;
    width: 200px;
    background: #333;
    color: #fff;
    border-radius: 7px;
    top: 42px;
    left: 0;
    padding: 15px 0 3px;
    a {
      display: block;
      padding: 0px 0 10px 17px;
      color: #fff;
      font-size: 0.875rem;
      i {
        margin: 0px 3px;
      }
    }
  }

  .itemmenu {
    width: 215px;
    top: 0;
    left: unset;
    right: 38px;
    padding: 20px 12px;
    text-align: left;
    a {
      padding: 0;
      margin: 0 0 12px 0;
      height: 28px;
      display: flex;
      align-items: center;
      &:last-child {
        margin: 0 0 0 0;
      }
      transition: 0.2s;
      &:hover {
        opacity: 0.65;
        cursor: pointer;
      }
    }
    .trash i {
      width: auto;
      margin: 0 4px 0 0px;
    }
  }

  #toggle:checked + .menu {
    display: block;
    position: absolute;
  }

  #overlay,
  #deleteOverlay,
  #addFolderOverlay,
  #menuOverlay,
  #itemMenuOverlay,
  #addFolderNameOverlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
  }

  #pencilDialog {
    width: 245px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    z-index: 3;
    color: #fff;
    border-radius: 13px;
    cursor: default;
    .pencilDialog__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ffffff20;
      padding: 15px;
    }
    .pencilDialog__title {
      font-size: 0.875rem;
    }
    #closeButton {
      display: block;
      width: 25px;
      height: 25px;
      position: relative;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        opacity: 0.5;
      }
    }
    #closeButton::before,
    #closeButton::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: #fff;
      transform: rotate(45deg);
      transform-origin: 0% 50%;
      position: absolute;
      top: 14%;
      left: 14%;
    }

    #closeButton::after {
      transform: rotate(-45deg);
      transform-origin: 100% 50%;
      left: auto;
      right: 14%;
    }
    .pencilDialog__bottom {
      padding: 30px 15px 15px;
      p {
        color: #999;
        font-size: 0.75rem;
      }
      .underline-input {
        width: 100%;
        border: none;
        border-bottom: 2px solid #fff;
        color: #fff;
        outline: none;
        background-color: #444;
        padding: 5px;
        margin-top: 4px;
        margin-bottom: 4px;
        border-radius: 0;
      }
      .characters {
        text-align: right;
      }
      .save {
        display: block;
        text-align: right;
        color: #358ccb;
        font-size: 0.875rem;
      }
    }
  }

  #deleteDialog {
    width: 295px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    z-index: 3;
    color: #fff;
    border-radius: 13px;
    .deleteDialog__title {
      padding: 20px 15px;
    }
    .deleteDialog__bottom {
      text-align: end;
      margin-bottom: 15px;
      a {
        padding: 0px 25px 0 0px;
      }
      .cancel {
        color: #358ccb;
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          filter: brightness(1.5);
        }
      }
      .delete {
        color: #be4949;
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          filter: brightness(1.5);
        }
      }
    }
  }

  #addFolderOverlay {
    .addFolderDialog__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ffffff20;
      padding: 15px;
    }
    .addFolderDialog__title {
      font-size: 0.875rem;
    }
    #addFolderCloseButton {
      color: #358ccb;
      cursor: pointer;
      transition: 0.2s;
      &:hover {
        filter: brightness(1.5);
      }
    }
    .addFolderDialog__center {
      margin: 20px;
      div {
        margin: 20px 0;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          opacity: 0.6;
        }
      }
      input[type="checkbox"] {
        transform: scale(1.5);
        margin-right: 8px;
      }
    }
    .addFolderDialog__bottom {
      border-top: 1px solid #ffffff20;
      padding: 15px;
      .addButton {
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          opacity: 0.6;
        }
      }
    }
    .newfolder-icon {
      width: 10%;
      margin-right: 10px;
    }
    .addFolderName__bottom {
      border-top: none;
      padding: 5px 15px 10px;
      p {
        color: #999;
        font-size: 0.75rem;
      }
      .underline-input {
        width: 100%;
        border: none;
        border-bottom: 2px solid #fff;
        color: #fff;
        outline: none;
        background-color: #444;
        padding: 5px;
        margin-top: 4px;
        margin-bottom: 4px;
        border-radius: 0;
      }
      .characters {
        text-align: right;
      }
    }
  }

  .saveButton__wrapper {
    display: flex;
    justify-content: end;
  }
  .save {
    display: block;
    text-align: right;
    color: #358ccb;
    font-size: 0.875rem;
    margin: 10px 0 5px;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      filter: brightness(1.5);
    }
  }
}

#menuDialog {
  width: 245px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  z-index: 3;
  color: #fff;
  border-radius: 13px;
  padding: 20px 12px;
  a {
    display: block;
    color: #fff;
    margin-bottom: 12px;
    height: 28px;
    display: flex;
    align-items: center;

    transition: 0.2s;
    &:hover {
      filter: brightness(0.75);
    }
    &:last-child {
      margin-bottom: 0px;
    }
    i {
      margin-right: 4px;
      width: 32px;
      text-align: center;
    }
  }
}

#itemmenuDialog {
  width: 245px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  z-index: 3;
  color: #fff;
  border-radius: 13px;
  padding: 20px 16px;
  a {
    display: block;
    color: #fff;
    margin-left: 0x;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .addfolder {
    &-icon {
      width: 10%;
      margin: 0 7px 3px 3px;
    }
  }
  .fa-trash-alt {
    margin: 0px 12px 0px 7px;
  }
}

.ec-favoriteRole {
  .ec-mypageHeader h1 {
    margin: 60px 0 30px;
  }
  & &__header {
    margin-bottom: 16px;
  }
  & &__itemList {
    @include reset_link;
    padding: 0;
    list-style: none;
  }
  & &__item {
    display: flex;
    margin-bottom: 8px;
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    width: calc(100% / 4);
    @include media-breakpoint-down(xl) {
      width: calc(100% / 3);
    }

    @include media-breakpoint-down(md) {
      width: calc(100% / 2);
    }

    &-image {
      margin-bottom: 10px;
      text-align: center;
    }
    img {
      width: auto;
      max-height: 100%;
    }
    @include media_desktop() {
      width: 100%;
    }

    .ec-closeBtn--circle {
      position: absolute;
      right: 10px;
      top: 10px;
      .ec-icon img {
        width: 1em;
        height: 1em;
      }
    }
  }

  .ec-favoriteSearch {
    margin: 0;
    padding: 20px 0 70px;
    .categoryList__wrapper {
      width: 100%;
      overflow-x: scroll;
      @include media-breakpoint-down(sm) {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 0 16px;
      }
    }
    &__categoryList {
      display: flex;
      padding: 0;
      list-style: none;
      overflow-x: auto;
      white-space: nowrap;
      width: fit-content;
      li {
        position: relative;
        background-color: #e7e7e7;
        border-radius: 0.4rem;
        padding: 0 10px;
        margin: 0 5px;
        height: 46px;
        font-size: 14px;
        line-height: 46px;
        min-width: 96px;
        text-align: center;

        transition: 0.2s;

        a {
          width: 50%;
          color: #525263;
        }

        &:hover {
          filter: brightness(1.05);
          cursor: pointer;
        }

        &.active {
          background-image: linear-gradient(to right, #4d96cb, #0a7acb);
          a {
            color: #fff;
            cursor: default;
          }
          .dropdown {
            color: #fff;
          }
          &:hover {
            filter: none;
            cursor: default;
          }
        }
      }
    }
    &__button {
      display: inline-block;
      margin: 0;
      color: #525263;
      i {
        margin: 0 -10px 0 15px;
      }
    }
    .dropdown {
      margin: 0 0 0 2px;
      i {
        border-radius: 50%;
        transition: 0.2s;
        padding: 6px;
        &:hover {
          background-color: #bbb;
          color: #ffffff;
        }
        ::before {
          display: inline-block;
        }
      }
      &:hover {
        color: #999;
        cursor: pointer;
      }
    }
  }

  .ec-itemLists__content {
    border-bottom: 1px #ccc solid;
    &:first-child {
      border-top: 1px #ccc solid;
    }
  }
  .ec-itemLists__content form .imageBlock {
    width: 224px;
    @include media-breakpoint-down(xl) {
      width: 25%;
    }
  }
  .ec-itemLists__content form .bodyBlock {
    width: calc(100% - 224px);
    @include media-breakpoint-down(xl) {
      width: 75%;
    }
  }
  .ec-itemLists__content form .bodyBlock .infoBlock {
    border-right: none;
    width: calc(100% - 50px - 184px);
  }

  & &__itemThumb {
    display: block;
    height: auto;
    margin: 0;
  }
  & &__itemTitle {
    margin-bottom: 2px;
  }
  & &__itemPrice {
    font-weight: bold;
    margin-bottom: 0;
  }
  .itemOption {
    position: relative;
    width: 50px;
    text-align: center;
    border-right: 1px solid #ccc;
    i {
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
      color: #fff;
      padding: 7px;
      border-radius: 50%;
      transition: 0.2s;
      margin-right: 4px;
      width: 32px;
      text-align: center;
      &:hover {
        filter: brightness(1.25);
        cursor: pointer;
      }
    }
    .addfolder-icon {
      width: 10%;
      margin: 0px 7px 3px 4px;
    }
    .trash {
      i {
        background: none;
      }
    }
    display: flex;
    align-items: center;
    .itemOption-inner {
      position: relative;
      i.itemOption-button {
        width: 30px;
        height: 30px;
        line-height: 30px;
        padding: 0;
      }
    }
  }
  .ec-select select {
    min-width: 140px;
    padding: 6px 12px 6px 6px;
  }

  .itemQuantity {
    flex: 10%;
    width: 10%;
    text-align: center;
    border-right: 1px solid #ccc;
    input {
      margin: 0 auto;
    }
  }
  .itemCart {
    flex: 20%;
    width: 25%;
  }
  .estimate {
    margin: 0 auto;
  }
  @include media-breakpoint-down(lg) {
    .ec-itemLists__content form .bodyBlock {
      display: flex;
      flex-wrap: wrap;
    }
    .ec-itemLists__content form .bodyBlock .infoBlock {
      width: calc(100% - 50px);
    }
    .ec-itemLists__content form .bodyBlock .itemOption {
      border-right: none;
      justify-content: flex-end;
    }
    .ec-itemLists__content form .bodyBlock .actionBlock {
      width: 100%;
      text-align: left;
      border-top: 1px #ccc dotted;
      margin-top: 16px;
      padding-top: 8px;
      display: block;
      .actionBlock__cart {
        display: flex;
        flex-wrap: wrap;
        .ec-cartNavi__label {
          margin-right: 12px;
          &:last-child {
            margin-right: 0;
            white-space: nowrap;
          }
        }
      }
    }
    .ec-select select {
      display: block;
    }
  }
  @include media-breakpoint-down(md) {
    .itemOption {
      border: none;
    }
    .itemSelection {
      select {
        width: 90%;
        margin: 0 auto;
      }
      i {
        position: absolute;
        top: 20%;
        right: 38px;
      }
      i:nth-child(2) {
        position: absolute;
        top: 66%;
        right: 38px;
      }
    }
    .itemQuantity {
      border: none;
      input {
        width: 45%;
        margin: 15px auto;
      }
    }
    .itemCart {
      margin-bottom: 20px;
    }
  }
  @include media-breakpoint-down(sm) {
    .ec-itemLists__content form .bodyBlock .infoBlock {
      width: calc(100% - 30px);
    }
    .itemOption {
      width: 30px;
    }
  }
}

.mypage {
  .ec-orderRecodeLists,
  .ec-repair {
    p {
      font-size: 1.125rem;
      font-weight: bold;
    }
    &__search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0;
      p {
        font-size: 1rem;
        font-weight: 500;
      }
      span {
        font-size: 2rem;
        font-weight: bold;
      }
      .ec-select {
        margin-bottom: 0;
        select {
          background: #fff;
          padding: 10px 25px;
        }
      }
    }
    table {
      width: 100%;
    }
    th {
      background: #f8f8f8;
    }
    th:nth-child(1),
    th:nth-child(2) {
      width: 25%;
    }
    th:nth-child(3) {
      width: 50%;
    }
    th,
    td {
      padding: 15px;
      border: 1px solid #ccc;
    }

    th:first-child,
    th:last-child,
    td:first-child,
    td:last-child {
      border-left: none;
      border-right: none;
    }
  }

  .ec-mypageOrderDetail {
    .order-no {
      margin: 0 0 10px;
    }
    .order-data {
      margin: 0;
    }
    .order-status {
      margin: 10px 0 25px;
    }
    .ec-orderRole__detailTtile {
      margin: 10px 0;
    }
    .ec-cartHeader .ec-cartHeader__label {
      background: #f3f3f3;
    }
    .ec-imageGrid {
      border-top: none;
    }
    .manufacturer-name {
      font-size: 0.75rem;
    }
    .product-name {
      font-size: 0.875rem;
      margin: 5px 0;
    }
    .product-detail {
      font-size: 0.75rem;
      font-weight: 500;
    }
    .ec-shopping__total {
      display: flex;
      justify-content: end;
      margin: 25px 0px;
      padding-bottom: 25px;
      border-bottom: 1px solid #ccc;
      p {
        font-weight: 500;
        padding: 5px 0 5px 20px;
      }
    }
    .ec-cartRole__totalWrap {
      padding: 15px 0;
      text-align: right;
      .totalprice-title {
        font-size: 1rem;
        font-weight: bold;
      }
      .totalprice {
        font-size: 1.2rem;
      }
    }
    .ec-orderDelivery__address {
      margin: 25px 0 70px;
      p {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 2px 0;
      }
      .delivery-title {
        font-size: 1rem;
        font-weight: bold;
      }
    }
    .ec-orderPayment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .payment-title {
        font-size: 1rem;
        font-weight: bold;
      }
      .ec-blockRadio {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 2px 0;
      }
    }
  }

  /*
  ────────────────────────────────────────────────────
  修理依頼共通
  */
  .ec-repairLists {
    .repairSubTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
        align-items: start;
      }
    }
    .newRepair {
      color: #fff;
      font-size: 0.875rem;
      padding: 13px 25px;
      border-radius: 25px;
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
      transition: 0.2s;
      &:hover {
        filter: brightness(1.15);
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 40px;
      }

      i {
        margin-right: 10px;
      }
    }
    .heading {
      font-weight: 700;
    }
    .data {
      font-weight: 400;
    }
    .detail-no {
      margin: 0 0 12px 0;
      p {
        font-size: 16px;
      }
    }
    .detail-data {
      margin: 0 0 40px 0;
      p {
        font-size: 16px;
        margin-bottom: 12px;
      }
    }
    .detail-title {
      margin: 10px 0;
      p {
        font-size: 16px;
      }
    }
    .table-wrapper {
      overflow-x: scroll;
      @include media-breakpoint-down(sm) {
        width: calc(100% + 32px);
        margin-left: -16px;
        padding: 0 16px;
      }
    }
    table,
    .repairTable {
      width: 100%;
      a {
        text-decoration: underline;
        transition: 0.2s;
        font-weight: 700;
        &:hover {
          filter: brightness(1.85);
        }
      }

      font-size: 0.875rem;
      th {
        height: 40px;
        padding: 15px;
        &:nth-child(1) {
          width: 160px;
        }
        &:nth-child(2) {
          width: 160px;
        }
        &:nth-child(3) {
          width: inherit;
        }
      }
      td {
        border-left: 1px solid #ccc;
      }
    }
    .comment-text {
      font-weight: 400;
      margin-bottom: 16px;
    }
    .detail-table {
      th:nth-child(1) {
        width: 200px;
      }
      @include media-breakpoint-down(sm) {
        display: block;
        padding: 8px 0px 16px 0px;
        border-radius: 4px;
        tbody,
        tr,
        th,
        td {
          display: block;
          width: 100%;
          height: auto;
          border: none;
          padding: 0;
          margin: 0;
        }
        th:nth-child(1) {
          width: 100%;
        }
        tr {
        }
        th {
          padding: 4px 6px;
          background-color: #f0f0f0;
        }
        td {
          padding: 12px 8px;
          margin-bottom: 16px;
          background-color: #fafafa;
        }
      }
    }

    .inputTitle p {
      font-size: 1.5rem;
      @include media-breakpoint-down(sm) {
        font-size: 1.25em;
      }
    }

    form {
      p {
        font-size: 0.875rem;
        margin: 30px 0 5px;
        font-weight: 700;
      }
      input[type="text"],
      textarea {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 2px;
      }
      input[type="text"] {
        height: 48px;
      }
      textarea {
        min-height: 240px;
      }
      .repairOrder-confirm {
        width: 15%;
        margin: 0 auto;
      }
      .ec-blockBtn--action {
        width: 16%;
      }
    }

    .ec-reportHeading p {
      font-size: 1.5rem;
    }

    .ec-text-ac p {
      font-weight: 500;
    }

    .repairOrder-complete {
      margin: 50px auto;
      width: fit-content;
    }

    .ec-repair__actions {
      display: flex;
      justify-content: center;
      margin: 40px auto 70px auto;

      .repairOrder-send {
        width: 50%;
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }
      .ec-blockBtn--cancel {
        background: #fff;
        font-weight: 500;
        border-radius: 5px;
        border: 1px solid #ccc;
        color: #000;
        width: 180px;
        transition: 0.2s;
        &:hover {
          background: #f3f3f3;
          border-color: #dddddd;
        }
      }
    }
    .ec-repair__actions-comfirm {
      .repairOrder-send {
        width: auto;
        margin: 0 0 0 16px;
        transition: 0.2s;
        &:hover {
          background-color: inherit;
        }
        @include media-breakpoint-down(md) {
          width: auto;
        }
      }
      .ec-blockBtn--cancel {
        width: 100px;
        margin: 0 16px 0 0;
        transition: 0.2s;
        text-decoration: none;
        &:hover {
          background: #f3f3f3;
          border-color: #dddddd;
          filter: none;
        }
      }
    }
  }
  .ec-repair {
    .ec-repair__actions {
    }
  }
}

.ec-mypageDelivery {
  .ec-mypageRole {
    .contact {
      p {
        font-size: 0.875rem;
        font-weight: 500;
      }
      a {
      }
    }

    .ec-addressList {
      width: 100%;
      border-collapse: collapse;
      tbody {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
    }
    .ec-addressList__item {
      border-bottom: none;
    }

    .ec-delivery-text {
      font-size: 1.125rem;
      font-weight: bold;
      margin: 10px 0;
    }
    .ec-addressList__address {
      padding: 30px 15px;
      div {
        font-size: 0.875rem;
      }
      .address-name {
        font-size: 0.875rem;
        font-weight: bold;
      }
    }
  }
  .ec-addressList .ec-addressList__action {
    position: unset;
    .ec-inlineBtn {
      padding: 6px 15px;
      border-radius: 5px;
      background: #eaeaea;
      font-size: 0.75rem;
      font-weight: 500;
    }
    .ec-inlineBtn-use {
      border-radius: 0;
      color: #fff;
      padding: 10px;
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
}

.ec-mypageRole {
  .ec-mypageHeader h1 {
    font-size: 2rem;
    font-weight: bold;
    margin: 60px 0 40px 0;
    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }
  }
  .username {
    font-size: 1.125rem;
    font-weight: bold;
    margin: 50px 0px;
  }
  .white-button {
    display: inline-block;
    background: #fff;
    font-weight: 500;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: #000;
    padding: 10px 20px;
    margin: 15px 0px;
    &:hover,
    &:active {
      color: #000;
      background: rgba(0, 0, 0, 0.1);
      border-color: #ccc;
    }
  }
  .ec-mypageNews {
    &__newsItem {
      display: flex;
      margin: 30px 0;
      font-size: 14px;
    }
    &__newsDate {
      margin-right: 1.5em;
      color: #333333;
    }
  }
  .ec-orderRecodeLists,
  .ec-repairRecodeLists {
    p {
      font-size: 1.125rem;
      font-weight: bold;
    }
    &__search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0;
      p {
        font-size: 1rem;
        font-weight: 500;
        white-space: nowrap;
        display: inline-block;
        margin-right: 0.5em;
        @include media-breakpoint-down(sm) {
          font-size: 0.85rem;
        }
      }
      span {
        font-size: 2rem;
        font-weight: bold;
        display: inline-block;
        padding: 0 0.25em;
        @include media-breakpoint-down(sm) {
          font-size: 1.5rem;
        }
      }
      .ec-select {
        margin-bottom: 0;
        display: inline-block;
        select {
          background: #fff;
          padding: 0.5rem;
          @include media-breakpoint-down(sm) {
            font-size: 0.85rem;
          }
        }
      }
    }
    table {
      width: 100%;
      a {
        text-decoration: underline;
        transition: 0.2s;
        font-weight: 700;
        &:hover {
          filter: brightness(1.85);
        }
      }
    }
    th {
      background: #f8f8f8;
    }
    th:nth-child(1) {
      width: 160px;
      @include media-breakpoint-down(sm) {
        width: inherit;
      }
    }
    th:nth-child(2) {
      width: 160px;
      @include media-breakpoint-down(sm) {
        width: inherit;
      }
    }
    th:nth-child(3) {
      width: inherit;
    }
    th,
    td {
      padding: 15px;
      border: 1px solid #ccc;
    }

    th:first-child,
    th:last-child,
    td:first-child,
    td:last-child {
      border-left: none;
      border-right: none;
    }
  }
  .repair-top-page {
    table {
      * {
        white-space: nowrap;
      }
    }
  }
  .repair-detail-page,
  .repair-comfirm-page {
    th {
      width: 200px !important;
      @include media-breakpoint-down(sm) {
        width: 100% !important;
      }
    }
    @include media-breakpoint-down(sm) {
      table {
        border-bottom: 1px solid #e4e4e4;
      }
      th {
        display: block;
        border-bottom: none;
        border-top-color: #e4e4e4;
        height: auto !important;
        font-weight: 700 !important;
      }
      td {
        display: block;
        border: none;
        padding: 15px 15px 30px 15px;
      }
    }
  }
  .repair-complete-page {
    .ec-reportHeading {
      border-top: none;
      h2 {
        font-size: 24px;
      }
    }
    .ec-text-ac {
      p {
        text-align: left;
      }
    }
  }

  .ec-mypageOrderDetail {
    p {
      font-size: 14px;
      line-height: 16px;
    }
    .ec-cartRole__priceWrap p,
    .ec-cartRole__totalWrap p {
      font-size: 16px;
      line-height: 16px;
    }
    .ec-cartRow {
      @include media-breakpoint-down(md) {
        display: block;
      }
      .ec-cartRow__contentColumn {
        @include media-breakpoint-down(md) {
          display: block;
          width: 100%;
          border-bottom: none;
        }
      }
      .ec-cartRow__amountColumn {
        display: table-cell;
        @include media-breakpoint-down(md) {
          display: block;
          width: 100%;
          padding-left: 100px;
          padding-bottom: 8px;
          text-align: right;
          border-bottom: none;
          div::before {
            content: "個数： ";
            display: inline;
            font-size: 12px;
            font-weight: 700;
          }
        }
      }
      .ec-cartRow__subtotalColumn {
        display: table-cell;
        @include media-breakpoint-down(md) {
          display: block;
          width: 100%;
          text-align: right;
          font-size: 16px;
          line-height: 16px;
          padding-bottom: 16px;
          .ec-cartRow__sutbtotal::before {
            content: "小計： ";
            display: inline;
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
          }
        }
      }
    }

    .order-no {
      margin: 0 0 30px;
    }
    .order-data {
      margin: 25px 0;
    }
    .ec-orderRole__detailTtile {
      margin: 10px 0;
    }
    .ec-cartHeader .ec-cartHeader__label {
      background: #f3f3f3;
    }
    .ec-imageGrid {
      border-top: none;
    }
    .product-name {
      font-size: 0.875rem;
      margin-bottom: 12px;
    }
    .product-detail {
      font-size: 0.75rem;
      font-weight: 500;
    }
    .ec-shopping__total {
      display: flex;
      justify-content: end;
      margin: 25px 0px;
      p {
        font-weight: 500;
        padding: 5px 0 5px 20px;
      }
    }
    .ec-cartRole__totalWrap {
      padding: 15px 0;
      text-align: right;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      font-size: 14px;
      line-height: 18px;
      p {
        font-weight: 500;
        span {
          display: inline-block;
          font-weight: bold;
          padding-left: 0.5em;
          font-size: 18px;
          line-height: 18px;
        }
      }
    }
    .ec-orderDelivery__address {
      margin: 60px 0 20px;
      p {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 2px 0;
      }
      .delivery-title {
        font-size: 1rem;
        font-weight: bold;
      }
    }
    .ec-orderPayment {
      padding-bottom: 100px;
      @include media-breakpoint-down(md) {
        padding-bottom: 40px;
      }

      .payment-title {
        font-size: 1rem;
        font-weight: bold;
      }
      .ec-blockRadio {
        font-size: 0.875rem;
        font-weight: 500;
        padding: 2px 0;
      }
    }
  }
}

.ec-mypageDelivery {
  .ec-mypageRole {
    .contact {
      p {
        font-size: 0.875rem;
        font-weight: 500;
        a {
          text-decoration: underline;
        }
      }
    }

    .ec-addressList {
      width: 100%;
      border-collapse: collapse;
      tbody {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
    }
    .ec-addressList__item {
      border-bottom: none;

      @include media-breakpoint-down(sm) {
        display: block;
        padding: 24px 0px;
      }
    }

    .ec-delivery-text {
      font-size: 1.125rem;
      font-weight: bold;
      margin: 10px 0;
    }
    .ec-addressList__address {
      padding: 30px 15px;

      @include media-breakpoint-down(sm) {
        display: block;
        margin-right: 0;
        width: 100%;
        padding: 0px 8px;
        margin-bottom: 16px;
      }

      div {
        font-size: 0.875rem;
      }
      .address-name {
        font-size: 0.875rem;
        font-weight: bold;
        margin-bottom: 0.5em;
      }
    }

    .ec-addressList__action {
      position: unset;

      @include media-breakpoint-down(sm) {
        display: block;
        padding: 0px 8px;
        text-align: left;
      }

      .ec-inlineBtn {
        padding: 6px 15px;
        border-radius: 5px;
        background: #eaeaea;
        font-size: 0.75rem;
        font-weight: 500;
      }
      .ec-inlineBtn-use {
        border-radius: 0;
        border-radius: 5px;
        color: #fff;
        padding: 10px;
        background-image: linear-gradient(to right, #4d96cb, #0a7acb);
        font-size: 0.75rem;
        font-weight: bold;
      }
    }
  }
}

.mypage {
  .ec-userManegement {
    .userManegementSubTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    p {
      font-size: 1.05rem;
      font-weight: bold;
    }
    .newUser {
      color: #fff;
      font-size: 0.875rem;
      padding: 10px 20px;
      border-radius: 4px;
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
      i {
        margin-right: 10px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 0.75rem;
        padding: 6px 12px;
        i {
          margin-right: 6px;
        }
      }
    }

    .ec-userManegementLists {
      .table-wrapper {
        display: block;
        width: calc(100% + 32px);
        margin: 20px 0 20px -16px;
        overflow: scroll;
        padding: 0 16px;
      }
      table {
        width: auto;
        min-width: 100%;
      }
      th,
      td {
        border: none;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
      }
      th {
        width: 25%;
        min-width: 6em;
        font-weight: 400;
        white-space: nowrap;
        padding: 8px 12px;
      }
      td:nth-child(4) {
        text-align: right;
      }
      .delete {
        color: #ce6262;
        margin: 0.25em 0.5em;
        display: inline-block;
        white-space: nowrap;
      }
      .edit {
        color: #6086cc;
        margin: 0.25em 0.5em;
        display: inline-block;
        white-space: nowrap;
      }
      a.newUser {
        transition: 0.2s;
        &:hover {
          filter: brightness(1.1);
        }
      }
      a.delete,
      a.edit {
        transition: 0.2s;
        &:hover {
          text-decoration: underline;
          filter: brightness(1.25);
        }
      }
    }

    form .form-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
    form .form-group div {
      width: calc(50% - 12px);
    }

    form .sp_form {
      display: none;
    }

    form {
      p {
        font-size: 0.875rem;
        margin: 30px 0 7px;
        font-weight: bold;
      }
      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="tel"] {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 2px;
        height: 48px;
        padding: 10px;
      }

      .form-group div .password-wrapper {
        width: 100%;
        max-width: 100%;
        border: 1px solid #ccc;
        border-radius: 2px;
        height: 48px;
        padding: 0px;
      }

      .password-wrapper .inputText,
      .password-wrapper .inputPassword {
        width: 100%;
        border: none;
        border-radius: 2px;
        height: 46px;
        font-size: 1rem;
        outline: none;
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      small {
        display: inline-block;
        font-size: 0.75rem;
        margin: 5px 0;
        text-indent: -1em;
        padding-left: 1em;
      }
      select {
        border: 1px solid #ccc;
        border-radius: 2px;
        height: 48px;
        padding: 10px;
        background: #f3f3f3;
      }
    }
    @include media-breakpoint-down(md) {
      form .form-group div {
        width: 100%;
      }
      form .pc_form {
        display: none;
      }
      form .sp_form {
        display: block;
      }
    }
    .optional {
      color: #fff;
      font-weight: 500;
      padding: 6px;
      margin-left: 10px;
      border-radius: 3px;
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    }
    .ec-userManegement__actions {
      width: 185px;
      margin: 40px auto;
    }
  }
}

.ec-passwordChange {
  .ec-passwordChangeLists {
    div {
      margin: 0 0 30px;
      @include media-breakpoint-down(sm) {
        margin: 0 auto 30px;
      }
      p {
        font-size: 0.875rem;
        margin-bottom: 8px;
      }
      .invalid-feedback {
        margin: -15px 0 15px;
      }
    }
  }

  .password-wrapper {
    max-width: 350px;
    margin: 0 0 15px;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  div .invalid-feedback {
    margin: -15px 0 30px;
  }

  input[type="password"],
  input[type="text"] {
    width: 100%;
    border: none;
    border-radius: 2px;
    height: 48px;
    font-size: 1rem;
    outline: none;
    &:focus {
      box-shadow: none;
    }
  }

  small {
    display: block;
    font-size: 0.75rem;
    margin: 10px 0;
    text-indent: -1em;
    padding-left: 1em;
  }

  .ec-passwordChange__actions {
    width: 128px;
  }
}
