@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/

.ec-layoutRole .ec-layoutRole__contents {
  padding: 0px 32px 32px 32px !important;

  @include media-breakpoint-down(lg) {
    padding: 0px 32px 0 32px !important;
  }

  @include media-breakpoint-down(sm) {
    padding: 0px !important;
  }
}
.ec-productList__right__freeword {
  padding: 10px !important;
  @include media-breakpoint-down(sm) {
    margin: 20px 10px 4px 10px;
    border-radius: 8px;
  }
}
.ec-free_word__button {
  margin: 0 4px;
  @include media-breakpoint-down(sm) {
    margin: 0 4px 0 0 !important;
  }
}

@media only screen and (min-width: 768px) {
  .ec-topicpath {
    padding-top: 0 !important;
  }
}

.ec-layoutRole__flexRight {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

/*───────────▲▲▲レイアウト系▲▲▲──────────────*/

#sidefilter_toggle_button {
  /*background-image: linear-gradient(to right, #4d96cb, #0a7acb);*/
  border: 1px solid #ccc;
  font-size: 16px;
  padding: 10px 20px;
  transition: 0.2s;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    opacity: 0.5;
  }
}

.ec-productRole {
  @include container;

  /*レイアウト系────────────────────────────────*/
  .ec-grid2 .ec-grid2__cell:nth-child(1) {
    width: 50%;
  }
  .ec-grid2 .ec-grid2__cell:nth-child(2) {
    width: 50%;
  }
  @include media-breakpoint-down(lg) {
    .ec-grid2 {
      display: block;
    }
    .ec-grid2 .ec-grid2__cell:nth-child(1) {
      width: 100%;
    }
    .ec-grid2 .ec-grid2__cell:nth-child(2) {
      width: 100%;
    }
  }
  @include media-breakpoint-down(lg) {
    .ec-grid2 .ec-grid2__cell:nth-child(1) {
      margin-bottom: 48px;
    }
  }
  /*レイアウト系────────────────────────────────*/

  /*item_visual系────────────────────────────────*/
  .item_visual_wrapper {
    position: relative;
  }
  .ec-sliderItemRole .arrow-left,
  .ec-sliderItemRole .arrow-right {
    top: calc(50% - 16px);
  }
  /*item_visual系────────────────────────────────*/

  & &__img {
    margin-right: 0;
    margin-bottom: 20px;
    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  & &__profile {
    margin: 0 -20px;
    padding: 32px 20px;
    border-radius: 12px;
    background: #f3f3f3;
    @include media-breakpoint-up(md) {
      margin: 0 24px;
      padding: 25px;
      background: #f8f8f8;
    }
    @include media-breakpoint-up(lg) {
      margin: 0 20px 0 32px;
      background: #f8f8f8;
    }
    .ec-select {
      select {
        border-color: #d2d5da;
        color: #000;
        font-weight: 400;
        background: #fff;
        width: unset;
        height: 32px;
        line-height: 32px;
        display: inline-block;
        padding: 0px 8px;
        border-radius: 5px;
        font-size: 14px;
      }
    }
  }
  & &__title {
    .ec-headingTitle {
      font-size: 18px;
      font-weight: bold;
      margin: 10px 0;
      @include media_desktop {
        font-size: 24px;
      }
    }
  }
  & &__tags {
    margin-top: 16px;
    padding: 0;
  }
  & &__tag {
    display: inline-block;
    color: #fff;
    padding: 6px 5px 5px 5px;
    margin: 3px 6px 3px 0;
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
  }
  & &__priceRegular {
    padding-top: 0px;
    color: #666;
  }
  & &__priceRegularTax {
    margin-left: 5px;
    font-size: 10px;
  }
  & &__price {
    color: #333;
    padding: 16px 0 0;
    border-bottom: 0;
    font-weight: 700;

    .ec-price__heading {
      display: block;
      font-size: 12px;
    }
    .ec-price__price {
      display: block;
      font-size: 32px;
      padding: 0;
      @include media-breakpoint-down(sm) {
        font-size: 28px;
      }
    }
  }
  & &__code {
    padding: 0;
    font-size: 14px;
    color: #666666;
    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }
  & &__category {
    padding: 14px 0;
    border-bottom: 1px dotted #ccc;
    a {
      color: #33a8d0;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  &__actions {
    padding: 14px 0;
    .ec-select {
      select {
        border-color: #d2d5da;
        color: #000;
        font-weight: 400;
        background: #f8f8f8;
        line-height: unset;
        display: inline-block;
        padding: 5px 4px;
        border-radius: 5px;
        font-size: 14px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  & &__btn {
    width: 100%;
    margin: 16px 0 10px;
    transition: 0.2s !important;
    &:hover {
      filter: brightness(0.9);
    }

    .favorite-btn {
      border-color: #d2d5da;
      color: #000;
      font-weight: 400;
      background: #fff;
      width: unset;
      height: 32px;
      line-height: 32px;
      display: inline-block;
      padding: 0px 12px;
      border-radius: 5px;
      font-size: 14px;
    }
  }
  .ec-grid2 {
    .arrow-wrap {
      position: relative;
    }
  }
  &__priceRegularPrice {
    font-size: 12px;
  }
  &__border {
    border-bottom: 1px solid #ccc;
    display: block;
    margin: 25px 0;
    width: 100%;
  }
  &__profile-bottom {
    .quantity-control {
      width: 100%;
      button {
        background: #ebebeb;
        border-radius: 50%;
        box-shadow: none;
        border: none;
      }
      .quantity-minus {
        padding: 3px 12px;
      }
      .quantity-plus {
        padding: 3px 10px;
      }
    }
    .stock {
      margin: 30px 0;
    }
    .ec-numberInput {
      padding: 0;
      height: auto;
      .form-label {
        margin-right: 10px;
        font-weight: bold;
      }
    }
    .ec-cartNavi__wrap {
      display: flex;
      justify-content: space-between;
      .ec-cartNavi__label {
        display: inline-block;
        position: relative;
        width: 48%;
        button {
          display: inline-block;
          color: #fff;
          padding: 5px;
          background-image: linear-gradient(to right, #4d96cb, #0a7acb);
          border: none;
          border-radius: 3px;
          padding: 13px 8px;
          max-width: unset;
          width: 100%;
          transition: 0.2s !important;
          &:hover {
            filter: brightness(1.2);
          }
        }
        .ec-cartNavi__order {
          background-image: linear-gradient(to right, #4d96cb, #0a7acb);
        }
        .ec-cartNavi__estimate {
          background-image: linear-gradient(to right, #db9919, #db6e1d);
        }
        &__badge {
          display: inline-block;
          min-width: 17px;
          position: absolute;
          right: -17px;
          color: #417bac;
          top: -14px;
          border: 3px solid #417bac;
          background: #fff;
          border-radius: 15px 15px;
          padding: 1px 11px;
          &__estimate {
            display: inline-block;
            min-width: 17px;
            position: absolute;
            right: -17px;
            color: #db831b;
            top: -14px;
            border: 3px solid #417bac;
            background: #fff;
            border-radius: 15px 15px;
            padding: 1px 11px;
            border: 3px solid #db831b;
          }
        }
      }
    }
  }

  &__description-container {
    margin-top: 60px;
  }

  &__description-wrap > p {
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    th {
      background-color: #f3f3f3;
      border: 1px solid #ccc;
      width: 30%;
      padding: 15px;
      font-weight: 500;
    }

    td {
      border: 1px solid #ccc;
      padding: 15px;
      width: 70%;
    }

    th:first-child,
    td:first-child {
      border-left: none;
    }

    th:last-child,
    td:last-child {
      border-right: none;
    }
  }
}

.ec-productList {
  display: flex;

  .ec-itemCards {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;

    @include media-breakpoint-down(sm) {
      width: calc(100% - 20px);
      margin-left: 10px;
      margin-right: 10px;
    }

    container-type: inline-size;
  }
  .ec-itemCards::before {
    content: "";
    display: block;
    order: 1;
    width: calc((100% / 4) - 12px);
  }
  .ec-itemCards::after {
    content: "";
    display: block;
    width: calc((100% / 4) - 12px);
  }

  .ec-itemCards__content {
    width: calc((100% / 4) - 12px);
    margin: 0 0 16px;
    background-color: #f8f8f8;
    border-radius: 15px;
    color: #333333;
    position: relative;

    transition: 0.2s;
    &:hover {
      transform: scale(1.02);
      box-shadow: 0px 5px 10px #c0c0c080;
      z-index: 1;
    }

    .ec-itemCards__detail {
      padding: 10px 12px 20px 12px;

      .ec-itemCards__manufacturer_name {
        font-size: 0.7rem;
      }

      .ec-itemCards__name {
        font-size: 14px;
        margin: 2px 0 6px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      .ec-itemCards__label {
        padding: 2px 5px 1px 5px;
        line-height: 1.7;
      }
    }
  }
  @container (max-width: 799.99px) {
    .ec-itemCards::before {
      display: none;
    }
    .ec-itemCards::after {
      width: calc((100% / 3) - 12px);
    }
    .ec-itemCards__content {
      width: calc((100% / 3) - 12px);
      margin: 0 0 16px;
    }
  }
  @container (max-width: 599.99px) {
    .ec-itemCards__content {
      width: calc((100% / 2) - 5px);
      margin: 0 0 10px 0px;
      border-radius: 8px;

      .ec-itemCards__img img {
        border-radius: 8px 8px 0 0;
      }
    }
  }

  &__left {
    flex: 23%;
    transition: 0.3s;
    position: relative;

    @include media-breakpoint-down(lg) {
      flex: 0%;
      width: 0%;
    }
    .ec-sidefilter {
      position: relative;
      left: 0px;
      transition: 0.3s;
      min-width: 260px;
      overflow-y: auto;
      z-index: 50;
      @include media-breakpoint-down(lg) {
        position: absolute;
        left: -400px;
        height: 100%;
        box-shadow: 0px 10px 20px 0px #00000030;
      }
    }
    &.show .ec-sidefilter {
      @include media-breakpoint-down(lg) {
        left: 0px;
      }
    }
  }

  &__right {
    flex: 77%;
    width: 77%;
    padding: 0 0 32px 32px;
    transition: 0.3s;

    @include media-breakpoint-down(lg) {
      flex: 100%;
      width: 100%;
      padding: 0;
    }

    @include media-breakpoint-down(md) {
      flex: 100%;
    }

    &__titlewrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-down(sm) {
        padding: 0 10px 10px 10px;
      }

      h1 {
        font-size: 24px;
        font-weight: bold;
      }
      button {
        padding: 5px 15px;
        border: 1px solid #6a6a6a;
        border-radius: 5px;
        background: none;
      }
    }

    &__category {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 16px;
      margin: 15px 0;
      @include media-breakpoint-down(xl) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 12px;
      }
      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr 1fr;
        margin: 0px 10px 20px;
        gap: 8px;
      }
      .category-wrap {
        display: grid;
        border: 1px solid #eeeeee;
        transition: 0.2s;
        background-color: #f9f9f9;
        border-radius: 6px;
        overflow: hidden;

        &:hover {
          opacity: 0.7;
          .category-img {
            img {
              transform: scale(1.1);
            }
          }
        }
        .category-flex {
          display: flex;
          align-items: center;
        }
        a {
          color: #333333;
        }
      }
      .category-img {
        width: 35%;
        overflow: hidden;
        img {
          width: 100%;
          transition: 0.2s;
          aspect-ratio: 1 / 1;
          object-fit: cover;
        }
      }
      .category-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 0.8125rem;
        width: 65%;
        padding-left: 12px;
        padding-right: 4px;
        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
    }

    &__viewwrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-down(sm) {
        display: block;
        background: #f8f8f8;
        padding: 20px;
      }
      .viewleft {
        p {
          font-size: 24px;
          font-weight: bold;
          span {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
      .viewright {
        display: flex;
        align-items: center;
        margin: 15px 0;

        @include media-breakpoint-down(sm) {
          margin-bottom: 0;
        }

        i {
          padding: 15px;
          border-radius: 5px;
          background: #eaeaeb;
          color: #a8aaab;
          &:hover {
            opacity: 0.7;
          }
          &.active:hover {
            opacity: 1;
          }
        }
        &__grid {
          margin: 0px 10px;
        }
        &__select {
          .ec-select {
            margin: 0;
            select {
              padding: 10px 10px;
              font-size: 16px;
            }
          }
        }
        .active {
          color: #fff;
          background: none;
          background-image: linear-gradient(to right, #4d96cb, #0a7acb);
        }
      }
    }

    .bg-w {
      @include media-breakpoint-down(sm) {
        background: #fff;
        padding: 0 10px 15px;
      }
    }

    &__freeword {
      background: #f8f8f8;
      padding: 10px 20px;
      .ec-free_word {
        text-align: left;
      }
    }
  }
}
.detail-wrap {
  max-width: 1250px;
}
.ec-product-recommend {
  &__wrap {
    background: #f8f8f8;
    margin: 50px 0;
    padding: 60px 0;
  }
  &__title {
    max-width: 1150px;
    margin: 0 auto;
    p {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }
  .ec-itemCards {
    width: 80%;
    margin: 0 auto;
    .ec-itemCards__detail {
      background: #fff;
    }
  }

  .ec-itemCardsSlick__detail {
    background: #fff;
  }
}

.ec-sidefilter {
  padding: 35px;
  background: #f8f8f8;
  border-radius: 15px;
  input {
    margin-right: 5px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    padding: 10px 0;
  }
  &__title {
    font-size: 18px;
    font-weight: bold;
    padding: 35px 0 20px;
    &:first-child {
      padding-top: 0;
    }
  }
  &__subtitle {
    font-size: 16px;
    font-weight: bold;
    padding: 15px 0px;
  }
  &__categorytitle {
    padding: 10px 0;
  }
  &__item {
    i {
      margin-right: 5px;
    }
  }
  .allfilter {
    padding: 10px 0;
    border: 1px solid #6a6a6a;
    border-radius: 5px;
    width: 100%;
  }

  &__buttonwrap {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    flex-direction: column;
    .filterclear {
      border: none;
      color: #666666;
      padding: 8px 20px;
      margin-bottom: 28px;
      border-radius: 5px;
      border: #dddddd 1px solid;
      transition: 0.2s;
      &:hover {
        filter: brightness(1.035);
      }
    }
    .filter {
      color: #fff;
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
      padding: 15px 20px;
      border: none;
      border-radius: 5px;
      transition: 0.2s;
      &:hover {
        filter: brightness(1.15);
        color: #fff;
      }
    }
  }
  &__range {
    margin: 15px 0;
  }
  &__number {
    margin: 25px 0 15px;
  }
  .price {
    width: 32%;
    padding: 10px;
  }
}

#price-range.ui-slider {
  background: #fff;
}

#price-range .ui-widget-header {
  background: #0975ff;
  border: none;
}

#price-range .ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #0975ff;
  border-radius: 50%;
  border: none;
  top: -0.2em;
}
