@import "../mixins/media";
@import "../mixins/projects";
/*
ログイン

主にログインフォームのスタイルを表示します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 6.1
*/

/*
ログインフォーム

ログインフォームを表示します。

ex [ログイン画面](http://demo3.ec-cube.net/mypage/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-login


Styleguide 6.1.1
*/
.ec-login-grid {
  display: flex;
  margin-bottom: 60px;
  margin-top: -20px;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
  }

  &__cell {
    flex: 50%;
  }
}

.ec-login {
  margin: 0 0 20px;
  height: auto;
  background: #f3f4f4;
  box-sizing: border-box;
  padding: 40px 7%;
  border-radius: 10px;
  @include media_desktop {
    margin: 0 16px;
    padding: 40px 7% 20px;
  }
  & &__icon {
    text-align: center;
  }
  .ec-icon {
    margin-bottom: 10px;
    img {
      width: 90px;
      height: 90px;
      display: inline-block;
    }
  }
  .login-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .login-text {
    font-size: 14px;
    line-height: 22px;
  }

  & &__input {
    margin: 25px 0;
    .ec-checkbox {
      span {
        margin-left: 5px;
        font-weight: normal;
      }
      label {
        font-weight: 500;
      }
    }
  }
  & &__actions {
    color: #fff;
    @include reset_link();
  }
  .action-button {
    color: #fff;
    background-image: linear-gradient(to right, #4d96cb, #0a7acb);
    font-size: 16px;
    border: none;
    font-weight: 500;
    border-radius: 5px;
  }
  & &__link {
    margin-top: 5px;
    margin-left: 0;
    color: linear-gradient(to right, #4d96cb, #0a7acb);
    margin: 25px auto;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    @include media_desktop {
      margin-left: 20px;
    }
  }
  .ec-errorMessage {
    color: $clrRed;
    margin-bottom: 20px;
  }
}

.signup-grid {
  background: #fff;
  padding: 40px 7%;
}
.contact {
  margin: 25px 0;
}

/*
ゲスト購入

ゲスト購入ボタンとそのフォームを表示します。

ex [ゲスト購入画面](http://demo3.ec-cube.net/shopping/login)

Markup:
include /assets/tmpl/elements/6.3.login.pug
+ec-guest
hoge

Styleguide 6.1.2
*/
.ec-guest {
  display: table;
  margin: 0;
  padding: 13%;
  height: auto;
  box-sizing: border-box;
  background: #f3f4f4;

  @include media_desktop {
    height: 100%;
    margin: 0 16px;
  }
  & &__inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    p {
      margin-bottom: 16px;
    }
  }
  & &__actions {
    display: block;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    @include reset_link();
  }
  & &__icon {
    font-size: 70px;
    text-align: center;
  }
}
