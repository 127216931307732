@import "../mixins/media";

/*
カート追加モーダル

カート追加モーダルに関する Project コンポーネントを定義します。

ex [商品一覧、商品詳細](http://demo3.ec-cube.net/products/list)

+ec-modal

Styleguide 13.4

*/

.ec-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;

  &.small {
    width: 30%;
  }

  &.full {
    width: 100%;
    height: 100%;
  }

  .ec-modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
  }

  .ec-modal-wrap {
    position: relative;
    border-radius: 2px;
    border: 1px solid #333;
    background-color: #fff;
    width: 90%;
    margin: 20px;
    padding: 40px 5px;
    border: none;
    border-radius: 10px;
    @include media_desktop {
      padding: 40px 10px;
      width: 50%;
      margin: 20px auto;
    }
  }

  .ec-modal-close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 10px;
    font-size: 20px;
    height: 30px;
    width: 20px;
    &:hover {
      color: #4b5361;
    }
  }

  .ec-modal-box {
    text-align: center;
  }

  .ec-role {
    margin-top: 20px;
  }

  #ec-modal-header {
    font-weight: bold;
  }
}
