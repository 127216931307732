@import "../mixins/media";
@import "../mixins/clearfix";

/*


トップページで使用されているオンラインストアについてのスタイルです。


*/

.ec-aboutShop {
  box-sizing: border-box;
  border-radius: 10px;
  background: #f8f8f8;
  padding: 20px 0 40px 0;
  margin: 30px;

  @include media-breakpoint-down(md) {
    padding: 12px 12px 52px 12px;
    border-radius: 0px;
    margin: 60px 0 0 0;
    border-radius: 0;
  }

  &__title {
    h2 {
      text-align: center;
      font-weight: bold;
      margin: 30px 0;
      @include media-breakpoint-down(md) {
        padding: 0 24px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 20px;
      }
    }
  }

  &__text {
    text-align: center;
    font-size: 14px;
    line-height: 1.7;
    margin: 0 auto 40px auto;
    max-width: 720px;
    padding: 0 24px;
    @include media-breakpoint-down(md) {
      padding: 0 8px;
    }
  }
  &__buttonWrapper {
    @include media-breakpoint-down(lg) {
      max-width: 480px;
      margin: 0 auto;
    }
    &__button {
      font-size: 16px;
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
      max-width: 186px;
      width: 25%;
      margin: 0 8px;
      color: #ffffff;
      transition: 0.3s;

      &:hover {
        filter: brightness(1.15);
      }
      @include media-breakpoint-down(lg) {
        width: 50%;
        margin: 8px 8px;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: none;
      }
    }
  }
}
