@import "../mixins/projects";
@import "../mixins/media";
/*
ヘルプページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-helpRole">
  <sg-wrapper-content/>


Styleguide 10.1
*/

/*▼ヘルプ系ページ共通のスタイル▼*/
.ec-helpRole {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    padding: 0 24px;
  }

  .ec-pageHeader h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 32px 0;
    padding: 8px 0;
    border-bottom: none;
    @include media-breakpoint-down(md) {
      border-top: none;
    }
  }

  .ec-off1Grid {
    display: block;
    .ec-off1Grid__cell {
      width: 100%;
      margin: 0;
      padding-bottom: 60px;
    }
  }
  .contents-body {
    margin: 0 0 60px 0;
    .section {
      margin: 0 0 60px 0;
    }
  }
  .contents-footer {
    margin: 0 0 60px 0;
  }
  .ec-heading-bold {
    font-size: 20px;
    font-weight: 700;
    margin: 20px 0 10px 0;
    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }
  a {
    text-decoration: underline;
  }
  h3.ec-heading-bold {
    font-size: 16px;
    margin-top: 2.5em;
  }
  p {
    font-size: 14px;
    margin: 0 0 8px 0;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0 0 8px 1em;
    padding: 0;
  }
  .index-list {
    margin-top: 40px;
  }

  .ec-borderedDefs {
    dl {
      padding: 0;
    }
    dt {
      padding: 16px 16px 16px 0;
      line-height: 1.75;
      .ec-label {
        margin-bottom: 0;
      }
    }
    dd {
      padding: 16px 16px 16px 0;
      line-height: 1.75;
      margin-bottom: 0;
    }
    @include media-breakpoint-down(md) {
      dt {
        padding-bottom: 0px;
        padding-right: 0;
      }
      dd {
        padding-top: 0px;
        padding-right: 0;
      }
    }
  }
}

.page-guide {
  .order-flow,
  .estimate-flow {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    img {
      box-sizing: border-box;
      width: 150px;
      height: auto;
      margin: 20px;
      display: block;
      @include media-breakpoint-down(lg) {
        width: calc((100% / 3) - 32px);
        margin: 16px;
      }
      @include media-breakpoint-down(sm) {
        width: calc((100% / 2) - 32px);
      }
    }
  }
}

.page-faq {
  .ec-pageHeader h1 {
    font-size: 2rem;
  }

  p {
    margin: 0;
  }

  ul li {
    list-style: none;
    margin: 0 0 10px 0;
  }

  .ec-faq-title {
    font-size: 1.125rem;
    font-weight: bold;
  }

  .accordion-wrap {
    margin: 20px 0;
  }

  .accordion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .accordion-title {
    background-color: #f3f3f3;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 15px 50px 15px 0;
    width: 100%;
    text-align: left;
    color: #333;
    font-size: 0.875rem;
    font-weight: bold;
    position: relative;
    text-indent: -2.9em;
    padding-left: 2.9em;
    cursor: pointer;
  }

  .icon {
    color: #358ccb;
    font-size: 1rem;
    font-weight: bold;
    margin: 0 15px;
  }

  .accordion-content {
    display: none;
    width: 100%;
    padding: 25px 25px 25px 1px;
    background-color: #fff;
    p {
      line-height: 1.75;
      margin-bottom: 24px;
    }
  }

  .accordion-answer {
    display: flex;
  }

  .accordion-title::after,
  .accordion-title::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 2px;
    background: #358ccb;
    top: 50%;
    left: 96%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
  }

  .accordion-title::before {
    width: 2px;
    height: 16px;
    transform: translate(-50%, -50%);
  }

  .accordion-title.open::before {
    opacity: 0;
    transform: translate(-50%, -50%) scaleX(0);
  }

  @include media-breakpoint-down(md) {
    .accordion-title::after,
    .accordion-title::before {
      left: 94%;
    }
  }
}
