.ec-about {
  background: #f8f8f8;
  border-radius: 15px;
  text-align: center;
  margin: 80px auto;
  padding: 70px 0;
  &__title {
    h2 {
      font-weight: bold;
      margin: 0;
    }
    p {
      padding: 40px 18%;
      a {
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }
  &__contents {
    ul {
      list-style: none;
      padding: 0;
      margin: 0px 65px;
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
    }
    &__link {
      display: block;
      margin: 0 18px;
      color: #fff;
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
      border-radius: 5px;
      padding: 20px 0px;
      transition: 0.2s;
      &:hover{
        filter: brightness(1.15);
        color: #fff;
      }
    }
  }
}
