@import "../mixins/projects";
@import "../mixins/clearfix";
@import "../mixins/media";

@mixin row {
  margin-left: ceil((30px / -2));
  margin-right: floor((30px / -2));
  @include clearfix;
}

@mixin makeSmColumn($columns) {
  position: relative;
  min-height: 1px;
  padding-left: (30px / 2);
  padding-right: (30px / 2);

  @media (min-width: $desktop) {
    float: left;
    width: percentage(($columns/ 12));
  }
}

/*
レイアウト

様々なレイアウトを変更する為のスタイル群です。

Styleguide 5.2
*/

.ec-layoutRole {
  &__contentBottom {
    margin-bottom: 60px;
    padding: 0 32px 0 32px !important;
    width: 100%;
  }
}

.ec-preparing {
  margin: 250px 10px;
  &__title {
    text-align: center;
  }
  &__action {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    a {
      width: 50%;
    }
    .ec-blockBtn--action {
      margin-bottom: 20px;
    }
    @include media_desktop {
      display: flex;
      flex-direction: unset;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 500px;
      a {
        width: 140px;
      }
    }
  }
}

/*
画像レイアウト

画像とテキストを水平に並べるレイアウトです。

画像は20%で表示されます。

ex [注文履歴 ログイン後→注文履歴ボタンを押下](http://demo3.ec-cube.net/mypage)

Markup:
.ec-imageGrid
  .ec-imageGrid__img: img(src="http://demo3.ec-cube.net/upload/save_image/0701113537_559351f959620.jpeg")
  .ec-imageGrid__content
    p.ec-font-bold ホーローマグ
    p ¥ 1,728 x 1

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 5.2.1
*/
.ec-imageGrid {
  display: table;
  @include borderTop;
  width: 100%;

  & &__img {
    display: table-cell;
    padding: 10px;
    width: 100px;

    @include media_desktop {
      padding: 10px;
      width: 130px;
    }

    img {
      width: 100%;
    }
  }
  & &__content {
    vertical-align: middle;
    display: table-cell;
    span {
      margin-left: 10px;
    }
    p {
      margin-bottom: 0;
    }
  }
}
