@import "../mixins/media";
@import "../mixins/clearfix";

/*
見出し

トップページで使用されている新着情報のスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.8.1
*/

.ec-newsRole {
  padding: 0 30px;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  &__title {
    h2 {
      font-weight: bold;
      margin: 30px 0 24px 0;
      @include media-breakpoint-down(md) {
        padding: 0 24px;
      }
    }
  }

  & &__news {
    box-sizing: border-box;
    border-radius: 10px;
    background: #f8f8f8;
    padding: 20px;

    @include media-breakpoint-down(md) {
      padding: 12px;
      border-radius: 0px;
    }
  }
  & &__newsItem {
    width: 100%;
    padding: 0 12px;

    &:last-of-type {
      margin-bottom: 20px;

      @include media_desktop {
        margin-bottom: 0;
      }
    }
    &:hover {
      background: #eaf3fa;
      border-radius: 10px;
    }

    @include media_desktop {
      padding: 18px 0;
    }
  }
  & &__newsHeading {
    cursor: pointer;

    @include media_desktop {
      display: flex;
      align-items: center;
    }
  }
  & &__newsDate {
    display: block;
    margin: 15px 0 5px;
    font-size: 12px;
    color: black;
    @include media-breakpoint-down(md) {
      padding-top: 12px;
    }

    @include media_desktop {
      display: inline-block;
      margin: 0;
      min-width: 120px;
      font-size: 14px;
      padding-left: 16px;
    }
  }
  & &__newsColumn {
    display: flex;

    @include media_desktop {
      display: inline-flex;
      min-width: calc(100% - 120px);
    }
  }

  & &__newsTitle {
    display: inline-block;
    margin-bottom: 10px;
    width: calc(100% - 56px);
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 1.6;

    @include media_desktop {
      margin-bottom: 0;
      line-height: 1.8;
    }
    @include media-breakpoint-down(md) {
      font-size: 14px;
    }
  }
  & &__newsClose {
    display: inline-block;
    width: 56px;
    position: relative;
  }
  & &__newsCloseBtn {
    display: inline-block;
    margin-left: auto;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    text-align: center;
    background: black;
    cursor: pointer;
    position: absolute;
    right: 16px;
  }
  & &__newsDescription {
    display: none;
    margin: 0 0 10px;
    padding: 12px 12px 28px 12px;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;

    @include media_desktop {
      margin: 20px 0 0;
      line-height: 1.8;
      padding: 0 12px 0 120px;
    }

    a {
      color: #0092c4;
    }
  }
  &__newsItem.is_active &_esc_newsDription {
    margin: 0 0 10px;
    padding: 0 12px 0 120px;
    @include media-breakpoint-down(md) {
      padding: 0 0 8px 0;
    }

    @include media_desktop {
      margin: 20px 0 0;
    }
  }
  &__newsItem.is_active &__newsCloseBtn i {
    display: inline-block;
    transform: rotateX(180deg) translateY(2px);
  }

  &__buttonWrap {
    text-align: right;
    &__button {
      display: inline-block;
      margin: 20px 0;
      font-size: 14px;
      color: #fff;
      background-image: linear-gradient(to right, #4d96cb, #0a7acb);
      border: 1px solid #fff;
      border-radius: 0.5rem;
      padding: 13px 20px;
    }
  }
  .fa,
  .fab,
  .fal,
  .far,
  .fas {
    margin-top: 4px;
  }
}
