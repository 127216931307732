@import "../mixins/media";
/*
トップページ

トップページ スライド部に関する Project コンポーネントを定義します。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/12.1.slider.pug
+ec-sliderRole

Styleguide 12.1
*/
.ec-sliderRole {
  @include container;
  margin-bottom: 24px;
  ul {
    padding: 0;
    list-style: none;
  }
}
.ec-sliderItemRole {
  @include container;
  margin-bottom: 24px;
  padding-left: 0;
  padding-right: 0;
  ul {
    padding: 0;
    list-style: none;
  }
  .item_nav {
    display: none;
    @include media_desktop {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: calc(100% - 48px + 1.66%);
      margin: 0 24px;
    }
  }
  .slideThumb {
    margin-bottom: 5px;
    width: 15%;
    opacity: 0.8;
    cursor: pointer;
    margin-right: 1.66%;

    &:focus {
      outline: none;
    }
    &:hover {
      opacity: 1;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      aspect-ratio: 1 / 1;
    }
  }
  .arrow-left {
    position: absolute;
    top: 33%;
    left: 0;
    z-index: 10;
  }
  .arrow-right {
    position: absolute;
    top: 33%;
    right: 0;
    z-index: 10;
  }
}
