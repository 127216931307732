@import "./variables";

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@mixin fadeIn($display: block, $time: 150ms) {
  display: $display;
  opacity: 1;
  visibility: visible;
  animation: fadeIn $time linear 0s;
}
@mixin fadeOut($time: 150ms) {
  opacity: 0;
  visibility: hidden;
  animation: fadeOut $time linear 0s;
}

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}
