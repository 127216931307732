@import "../mixins/media";
@import "../mixins/clearfix";
/*
お知らせ

新着情報やバナーなどの掲載項目を紹介していきます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 8.1
*/

/*
新着情報

新着情報の掲載をします。

ex [トップページ　新着情報部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+ec-news

Styleguide 8.1.1
*/
.ec-news {
  margin-bottom: 16px;
  background: #f8f8f8;
  @include media_desktop {
    margin-right: 3%;
  }
  @include media_desktop {
    margin-bottom: 32px;
  }
  & &__title {
    font-weight: bold;
    padding: 8px;
    font-size: 16px;
    text-align: center;
    @include media_desktop {
      padding: 16px;
      text-align: left;
      font-size: 24px;
    }
  }
  & &__items {
    padding: 0;
    list-style: none;
    border-top: 1px dotted #ccc;
  }
}
/*
折りたたみ項目

折りたたみ項目を掲載します。

ex [トップページ　折りたたみ項目部分](http://demo3.ec-cube.net/)

Markup:
include /assets/tmpl/elements/8.1.info.pug
+b.ec-news
        +e.title 新着情報
        +e.UL.items
            +e.LI.item
                +b.ec-newsline.is_active
                    +e.info
                        +e.date 2016/09/29
                        +e.comment サイトオープンしました
                        +e.close
                            a.ec-closeBtn--circle
                                span.ec-closeBtn--circle__icon
                                    .ec-icon
                                        img(src='/moc/icon/angle-down-white.svg', alt='')
                    +e.description 一人暮らしからオフィスなどさまざまなシーンで あなたの生活をサポートするグッズをご家庭へお届けします！

Styleguide 8.1.2
*/
.ec-newsline {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0 16px;
  & &__info {
    width: 100%;
    padding: 16px 0;
    @include clearfix;
  }
  & &__date {
    display: inline-block;
    margin-right: 10px;
    float: left;
  }
  & &__comment {
    display: inline-block;
    float: left;
  }
  & &__close {
    float: right;
    display: inline-block;
    text-align: right;
    .ec-closeBtn--circle {
      display: inline-block;
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;
    }
  }
  & &__description {
    width: 100%;
    height: 0;
    transition: all 0.2s ease-out;
  }

  &.is_active &__description {
    height: auto;
    transition: all 0.2s ease-out;
    padding-bottom: 16px;
  }
  &.is_active .ec-icon img {
    transform: rotateX(180deg);
  }
}
