.ec-related-item {
  margin: 0px auto 80px auto;

  &__title {
    h2 {
      font-weight: bold;
      margin: 30px 0;
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  &__wrap {
    &__categoryList {
      display: flex;
      padding: 0;
      margin: 30px 0;

      li {
        list-style: none;
      }

      &__button {
        display: inline-block;
        margin: 0;
        font-size: 14px;
        color: #525263;
        border: 1px solid #fff;
        border-radius: 0.5rem;
        padding: 10px 20px;

        &.active {
          color: #fff;
          background-image: linear-gradient(to right, #4d96cb, #0a7acb);
        }
      }
    }
  }

  .ec-itemCardsSlick .slick-dots li button:before {
    content: "" !important;
  }
}
