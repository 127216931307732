@import "../mixins/btn";
@import "../mixins/media";

/*
ボタン

トップページで使用されているボタンのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.3
*/

/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

Markup:
.ec-inlineBtn--top more

Styleguide 12.3.1
*/
.ec-inlineBtn--top {
  width: 20%;
  display: block;
  margin: 20px 0 20px auto;
  text-align: center;
  font-size: 14px;
  color: #fff;
  background-image: linear-gradient(to right, #4d96cb, #0a7acb);
  border: 1px solid #fff;
  border-radius: 0.5rem;
  padding: 13px 20px;
}

/*
ロングボタン（全幅）

ロングタイプのボタンです。

Markup:
.ec-blockBtn--top 商品一覧へ

Styleguide 2.1.2
*/
.ec-blockBtn--top {
  @include _btn(white, black, black);
  display: block;
  height: 56px;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;

  @include media_desktop {
    max-width: 260px;
  }
}
